import React from 'react';

// Components
import { ListSelection } from 'components/tools/list_selection';
import { FiBook } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// API
import { searchSchools } from 'api/utils';

export function SelectSchool({ value, setValue, disabled, disabledClear, ...props }){

  const { t } = useTranslation("common");
  const [schools] = useSearch(searchSchools);
  return <ListSelection value={value || null}
                        setValue={setValue}
                        disabled={disabled}
                        values={schools}
                        label={t('school')}
                        Icon={FiBook}
                        getKey={(d)=>d.slug}
                        format={(d)=>d.name}
                        formatSelection={(d)=>d.short_name}
                        withClearSelection={!disabledClear}
                        {...props}
                        />
}