/* eslint-disable */
import React from "react";

// Components
import { CardContainer } from 'components/core/container';
import { CardSectionHeading } from 'components/core/typo';
import { Error } from 'components/core/typo'
import { ListSelection } from 'components/tools/list_selection';
import { SearchStudents } from 'components/tools/student_search';
import { SelectSchool } from 'components/tools/schools_list';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { FiUser, FiBook } from "react-icons/fi";
import { SelectDateInterval } from 'components/tools/select_date';
import { timeDay } from 'd3';
import { FiInfo } from 'react-icons/fi';
import { TextInput } from 'components/core/inputs';

// API
import { createStudySessionExclusion } from "@/api/study_sessions";

// Hooks
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useAPI } from "@/hooks/useAPI";



export function CreateAADExclusionDate({onResult}) {
    const { t } = useTranslation('common');
    const types = [
        { value: "student", label: t("aad-exclusion-date.student.type") },
        { value: "school", label: t("aad-exclusion-date.school.type") }
    ]
    const [type, setType] = useState(types[0]);
    const [school, setSchool] = useState(null);
    const [student, setStudent] = useState(null);
    const [date, setDate] = useState({ fromDate: null, toDate: null });
    const [description, setDescription] = useState("");
    const handleChangeType = (value) => {
        setType(value)
        setSchool(null)
        setStudent(null)
    }
    const isValid = () => {
        if (!date.fromDate || !date.toDate) return false;
        if (type.value === "school" && !school) return false;
        if (type.value === "student" && !student) return false;
        return true;
    }
    const handleResult = (result) => {
        if (result) {
            onResult?.(result)
        }
    }
    const [,{execute,error,loading }] = useAPI(createStudySessionExclusion, {student, school, description, ...date},{immediate:false, camelize:true, onResult:handleResult});
    return <div className="flex-1 flex flex-col">
        <CardContainer className="mb-6">
            <CardSectionHeading
                title={
                    <span className="flex items-center">
                        <div>{t("aad-exclusion-date.create.title")}</div>
                    </span>
                }
            />
            <ModifiableSelectField
                Field={() => <ListSelection
                    Icon={type.value === "student" ? FiUser : FiBook}
                    values={types}
                    value={type}
                    setValue={handleChangeType}
                    getKey={(d) => d.value}
                    format={(d) => d.label}
                    label={t("type")}
                    optionsClassName={""}
                    selectionClassName="!inline"
                />}
                label={t("aad-exclusion-date.type.title")}
                subLabel={t("aad-exclusion-date.type.description")}
                modify={true}
                canRemoveSelection={false}
            />
        </CardContainer>
        <CardContainer className="mb-6 space-y-6">

            <TextInput
                label={t("aad-exclusion-date.description.title")}
                value={description}
                onChange={(description) => setDescription(description)}
            />


            {
                type.value === "school" ?
                    <ModifiableSelectField
                        key={"school"}
                        Field={SelectSchool}
                        label={t("school")}
                        subLabel={t("aad-exclusion-date.school.description")}
                        modify={true}
                        noBorder
                        canRemoveSelection={true}
                        value={school}
                        setValue={setSchool}
                        selectionClassName="!inline"

                    />
                    :
                    <ModifiableSelectField
                        key={"student"}
                        Field={SearchStudents}
                        label={t("student")}
                        subLabel={t("aad-exclusion-date.student.description")}
                        modify={true}
                        noBorder
                        canRemoveSelection={true}
                        value={student}
                        setValue={setStudent}
                    />
            }


            <ModifiableSelectField
                Field={SelectDateInterval}
                label={t("aad-exclusion-date.dates.title")}
                subLabel={t("aad-exclusion-date.dates.description")}
                modify={true}
                noBorder
                value={null}
                canRemoveSelection={false}
                setValue={({ fromDate, toDate }) => setDate(d => ({ ...d, fromDate, toDate }))}
            />
            {
                date.fromDate && date.toDate &&
                <p className={"text-gray-800 bg-gray-100 rounded-md p-3"}>
                    <FiInfo className="inline mr-2" />{t("add-meeting-date.youve-selected", { days: timeDay.count(date.fromDate, date.toDate) + 1 })}
                </p>
            }


            <Button variant="active" className="w-full" size="lg" disabled={!isValid() || loading} loading={loading} onClick={execute} >{t("aad-exclusion-date.create.save-btn")}</Button>
            {error && <Error.Text {...error} />}
        </CardContainer>
    </div>
}