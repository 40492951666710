import React from 'react';

// Components
import { CreateNote } from 'components/notes/create';
import { Modal } from 'components/core/modal';
import { Button } from 'components/core/button';
import { Fragment } from 'react';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInternetConnection } from '@/contexts/internet-connection';
import {  WifiOff } from 'lucide-react';

export function CreateNoteInModal({open, setOpen, creatorProps}){ 
  const {isOnline} = useInternetConnection();
  const {t} = useTranslation();
  return <Modal open={open} setOpen={setOpen} size="xl" disableOutsideClick={true}>
           <CreateNote {...creatorProps}/> 
           {!isOnline && <Alert variant={"destructive"} className='bg-red-100 mt-4'>
            <WifiOff className="h-4 w-4" />
            <AlertTitle>{t("offline-alert-note-modal.title")}</AlertTitle>
            <AlertDescription>
              {t("offline-alert-note-modal.message")}
            </AlertDescription>
          </Alert>}
        </Modal>
}

export function CreateNoteButton({creatorProps}){
  const [open, setOpen] = useState(false);
  const {t} = useTranslation('common')

  return <Fragment>
          <Button color="gray" size="md" onClick={()=>setOpen(true)}>{t("add-note")}</Button>
          <CreateNoteInModal open={open} setOpen={setOpen} creatorProps={creatorProps}/>
        </Fragment>
}