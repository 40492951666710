/* eslint-disable no-unused-vars */
import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI';
import { useUserV2 } from 'hooks/useUser';
// API
import { ansertTOTPChallenge } from 'api/auth';

// Components
import { CenterViewLayout } from 'components/core/layouts/center';
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
  } from "@/components/ui/input-otp"
  import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { Error} from 'components/core/typo'
import { Navigate, useSearchParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Button as Btn } from '@/components/core/button';

export function TOTPChallengePage(){
  const { t } = useTranslation(["auth"]);
  const [code, setCode] = useState("");
  const {setUser}= useUserV2();
  const [searchParams] = useSearchParams();
  const [result, {loading, error, execute}] = useAPI(ansertTOTPChallenge, {token: code},
                                                  {onResult: (({user})=>{
                                                    if (user){
                                                      setUser(user);
                                                    }
                                                  }),
                                                  immediate: false});
  const onSubmit = useCallback((e)=>{
    if (e) e.preventDefault();
    execute();
  }, [execute]);

  if (result){
    if (searchParams.get("ref")){
        return <Navigate to={searchParams.get("ref")} state={result}/>
    }
    return <Navigate to="/" state={result}/>
  }

  return <CenterViewLayout>
            <form className="space-y-6" onSubmit={onSubmit}>
            <img src="/assets/logo-HD.png" className="h-auto max-w-full mx-auto max-h-24"/>
              <div className='border rounded-md p-6'>
                <h4 className="text-lg font-medium text-gray-900">{t("totp-challenge.title")}</h4>
                <p className="mt-2 text-sm text-gray-600">{t("totp-challenge.description")}</p>
                <div className=' md:flex items-center justify-center py-4 '>
                      <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS} onChange={setCode} value={code}>
                      <InputOTPGroup>
                          <InputOTPSlot index={0} />
                          <InputOTPSlot index={1} />
                          <InputOTPSlot index={2} />
                        </InputOTPGroup>
                        <InputOTPSeparator />
                        <InputOTPGroup>
                          <InputOTPSlot index={3} />
                          <InputOTPSlot index={4} />
                          <InputOTPSlot index={5} />
                        </InputOTPGroup>
                    </InputOTP>
                </div>
                <Button type="submit" loading={loading} disabled={loading|| code.length !=6} className="w-full mb-3" size="lg">
                    {t("totp-challenge.submit")}
                </Button>
              </div>
            </form>
            {error? <Error.Text {...error}/>: null}
            
              {/* Back */}
              <Btn href="/login" size="sm"  color="inlineLink">
                  {t("totp-challenge.back-to-login")}
              </Btn>
         </CenterViewLayout>
}
