
export const options = {
    overrides: {
        div: {
          props: {
            className: "text-base  text-gray-500 relative"
          }
        },
        h1: {
            props: {
                className: 'mt-12 mb-5 text-3xl font-bold tracking-tight text-gray-900 text-center sm:text-4xl',
            },
        },
        h2: {
            props: {
                className: 'mt-12 text-2xl font-bold  text-orange-600 text-left sm:text-3xl pt-8',
            },
        },
        h3: {
            props: {
                className: 'font-semibold text-black text-xl mt-8 mb-2 ',
            },
        },
        p: {
            props: {
                className: 'text-gray-800 pb-3  text-base ',
            },
        },
        a: {
            props: {
                className: 'text-gray-800 text-orange-500 text-base ',
            },
        },
        table: {
            props: {
                className: 'text-gray-800 min-w-full divide-y divide-gray-300 mb-4 text-base ',
            },
        },
        th: {
            props: {
                className: 'text-gray-800 py-1.5 px-3 text-left text-sm font-semibold',
            },
        },
        ul: {
            props: {
                className: 'md:border-l-2 text-gray-800 list-decimal list-outside pl-3 ml-3 pt-3 space-y-1 text-base  max-w-3xl md:ml-[12em] lg:ml-[16em] md:pl-[4em] ',
            },
        },
        li: {
            props: {
                className: 'text-gray-800 text-base  pb-4',
            },
        },
        ol: {
            props: {
                className: 'ml-6 lg:ml-12 py-3 list-disc',
            },
        },
        img: {
            props: {
                className: 'w-full max-w-2xl shadow-2xl rounded-lg mt-2 mb-6',
            },
        },
    },
}