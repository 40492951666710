/* eslint-disable */
import React from "react";

// Components
import { CardContainer } from "components/core/container";
import { CardSectionHeading } from "components/core/typo";
import { Button } from "components/ui/button";
import { Error } from "components/core/typo";
import { TextInput } from "components/core/inputs";
import { ListSelection } from 'components/tools/list_selection';
import { SearchStudents } from 'components/tools/student_search';
import { SelectSchool } from 'components/tools/schools_list';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { FiUser, FiBook } from "react-icons/fi";
import { SelectDateInterval } from 'components/tools/select_date';
import { timeDay } from 'd3';
import { FiInfo } from 'react-icons/fi';
import { dateParse } from "utils/locale";
import { recursiveDecamelize } from "@/utils/casing";
import { useState } from "react";

// API
import { updateStudySessionExclusion, deleteStudySessionExclusion } from "@/api/study_sessions";

// Hooks
import { useTranslation } from "react-i18next";
import { useAPI } from "hooks/useAPI";


export function ModifyAADExclusionDate({ value, setValue, onDelete }) {
    const { t } = useTranslation("common");
    const types = [
        { value: "student", label: t("aad-exclusion-date.student.type") },
        { value: "school", label: t("aad-exclusion-date.school.type") }
    ]
    const type = value.student ? types.find(t => t.value === "student") : types.find(t => t.value === "school");
    const [school, setSchool] = useState(value.school ? recursiveDecamelize(value.school) : null);
    const [student, setStudent] = useState(value.student || null);
    const [date, setDate] = useState({ fromDate: dateParse(value.fromDate), toDate: dateParse(value.toDate) });
    const [description, setDescription] = useState(value.description || "");
    const handleResult = (result) => {
        if (result) {
            setValue?.(result)
        }
    }
    const [, { execute, error, loading }] = useAPI(updateStudySessionExclusion, { id: value?.id, student, school, description, ...date }, { immediate: false, camelize: true, onResult: handleResult });

    const isValid = () => {
        if (!date.fromDate || !date.toDate) return false;
        if (type.value === "school" && !school) return false;
        if (type.value === "student" && !student) return false;
        return true;
    }
    return (
        <div className="flex-1 flex flex-col">
            <CardContainer className="mb-6">
                <CardSectionHeading
                    title={
                        <span className="flex items-center">
                            <div>{t("aad-exclusion-date.modify.title")}</div>
                        </span>
                    }
                />
                <ModifiableSelectField
                    Field={() => <ListSelection
                        Icon={type.value === "student" ? FiUser : FiBook}
                        values={types}
                        value={type}
                        setValue={() => { }}
                        getKey={(d) => d.value}
                        format={(d) => d.label}
                        label={t("type")}
                        optionsClassName={""}
                        selectionClassName="!inline"
                        disabled
                    />}
                    label={t("aad-exclusion-date.type.title")}
                    subLabel={t("aad-exclusion-date.type.description")}
                    modify={false}
                    canRemoveSelection={false}
                />
            </CardContainer>
            <CardContainer className="mb-6 space-y-8">
                <TextInput
                    label={t("aad-exclusion-date.description.title")}
                    defaultValue={value.description || ""}
                    onChange={setDescription}
                />


                {
                    type.value === "school" ?
                        <ModifiableSelectField
                            key={"school"}
                            Field={SelectSchool}
                            label={t("school")}
                            subLabel={t("aad-exclusion-date.school.description")}
                            modify={true}
                            noBorder
                            canRemoveSelection={true}
                            value={school}
                            setValue={setSchool}
                            selectionClassName="!inline"

                        />
                        :
                        <ModifiableSelectField
                            key={"student"}
                            Field={SearchStudents}
                            label={t("student")}
                            subLabel={t("aad-exclusion-date.student.description")}
                            modify={true}
                            noBorder
                            canRemoveSelection={true}
                            value={student}
                            setValue={setStudent}
                        />
                }


                <ModifiableSelectField
                    Field={SelectDateInterval}
                    label={t("aad-exclusion-date.dates.title")}
                    subLabel={t("aad-exclusion-date.dates.description")}
                    modify={true}
                    noBorder
                    value={date}
                    canRemoveSelection={false}
                    setValue={({ fromDate, toDate }) => setDate({ fromDate, toDate })}
                />
                {
                    date.fromDate && date.toDate &&
                    <p className={"text-gray-800 bg-gray-100 rounded-md p-3"}>
                        <FiInfo className="inline mr-2" />{t("add-meeting-date.youve-selected", { days: timeDay.count(date.fromDate, date.toDate) + 1 })}
                    </p>
                }
                <Button variant="active" className="w-full" size="lg" disabled={!isValid() || loading} loading={loading} onClick={execute} >{t("aad-exclusion-date.modify.save-btn")}</Button>
                {error && <Error.Text {...error} />}

                <div className="pt-10">
                    <DeleteButton value={value} onDelete={onDelete} />
                </div>
            </CardContainer>
        </div>
    );
}


function DeleteButton({ value, onDelete }) {
    const { t } = useTranslation("common");
    const [, { execute, loading, error }] = useAPI(
        deleteStudySessionExclusion,
        { id: value?.id },
        { onResult: () => onDelete?.(value), immediate: false }
    );

    return (
        <div className="border-2 border-red-500 rounded-lg p-3 w-full space-y-3">
            <div>
                <h3 className="font-medium text-red-600">
                    {t("aad-exclusion-date.delete.title")}
                </h3>
                <p className="text-gray-500">
                    {t("aad-exclusion-date.delete.description")}
                </p>
            </div>
            <Button
                loading={loading}
                onClick={execute}
                variant="deleteContrast"
                size="lg"
                className="whitespace-nowrap w-full text-base">
                {t("aad-exclusion-date.delete.btn-confirm")}
            </Button>
            {error ? <Error.Text {...error} /> : null}
        </div>
    );
}
