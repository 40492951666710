/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Note {
    id: string;
    content: string;
    code: { code: string, name: string };
    commentTypes: string[];
    openedAt: Date;
    closedAt?: Date;
    student?: any;
    tutor?: any;
    pairing?: any;
    shouldDiscuss: boolean;
    commentDisplayDate: Date;
    minutes: number;
    documentGroup?: string;
    period?: string;
    commentsToLink: string[];
    lastUpdated: Date;
}

interface NotesContextType {
    notes: Note[];
    setNotes: React.Dispatch<React.SetStateAction<Note[]>>;
    createNote: (note: Omit<Note, 'id'>) => string;
    updateNote: (id: string, updatedNote: Partial<Note>) => void;
    deleteNote: (id?: string) => void;
    selectedNote: Note | null;
    setSelectedNote: (id: string) => void;
    loadingSelectedNote: boolean;
}

const NotesContext = createContext<NotesContextType | undefined>(undefined);

interface NoteLocalStorageProviderProps {
    children: ReactNode;
}

export const NoteLocalStorageProvider: React.FC<NoteLocalStorageProviderProps> = ({ children }) => {
    const [notes, setNotes] = useState<Note[]>(() => {
        const savedNotes = localStorage.getItem('notes');
        return savedNotes ? JSON.parse(savedNotes) : [];
    });
    const [selectedNote, setSelectedNoteState] = useState<Note | null>(null);
    const [loadingSelectedNote, setLoadingSelectedNote] = useState(false);

    useEffect(() => {
        localStorage.setItem('notes', JSON.stringify(notes));
    }, [notes]);

    useEffect(() => {
        if (selectedNote) {
            setLoadingSelectedNote(true);
            const timer = setTimeout(() => {
                setLoadingSelectedNote(false);
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [selectedNote]);

    const createNote = (note: Omit<Note, 'id'>): string => {
        const newNote: Note = { id: uuidv4(), ...note, lastUpdated: new Date() };
        setNotes((prevNotes) => [...prevNotes, newNote]);
        return newNote.id;
    };

    const updateNote = (id: string, updatedNote: Partial<Note>) => {
        setNotes((prevNotes) =>
            prevNotes.map((note) => (note.id === id ? { ...note, ...updatedNote, lastUpdated: new Date() } : note))
        );
    };

    const deleteNote = (id?: string) => {
        if (!id) return;
        setNotes((prevNotes) => prevNotes.filter((note) => note.id !== id));
    };

    const setSelectedNote = (id: string) => {
        const note = notes.find((note) => note.id === id) || null;
        setSelectedNoteState(note);
    };

    return (
        <NotesContext.Provider value={{ notes, setNotes, createNote, updateNote, deleteNote, selectedNote, setSelectedNote, loadingSelectedNote }}>
            {children}
        </NotesContext.Provider>
    );
};

export const useLocalNotes = (): NotesContextType => {
    const context = useContext(NotesContext);
    if (!context) {
        throw new Error('useLocalNotes must be used within a NoteLocalStorageProvider');
    }
    return context;
};
