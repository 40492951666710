export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }
  
  export function formatPhoneNumberHidden(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2].replace(/./g, '*') + '-' + match[3].replace(/.(?=.{2})/g, '*');
    }
    return phoneNumberString;
  }
  

// Validate phone number
export function validatePhoneNumber(phoneNumberString){
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return true;
      }
      return false;
}

export function nameToInitials(firstname, lastname) {
  let value = [];
  if (lastname) {
      let parts = lastname.split(" ");
      for (let part of parts) {
          let sb = [];
          for (let subpart of part.split("-")) {
              if (subpart.length > 0) {
                  sb.push(subpart[0] + ".");
              }
          }
          value.push(sb.join("-"));
      }
  }
  return firstname+" "+value.join(" ");
}