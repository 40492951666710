/* eslint-disable */

import React from "react";

import { Fragment, useMemo, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DoubleMonthView } from "components/tools/double_month_view";
import { Skeleton } from "@/components/core/skeleton";
import { Info } from "components/core/info";
import { EmptyState } from "components/core/empty";
import { Table } from "components/core/table";
import { Paginate } from "components/core/paginate";
import { Error } from "components/core/typo";
import { searchStudySessionExclusions } from "@/api/study_sessions";
import { useSearch } from "@/hooks/useSearch";
import { SearchContextProvider, SearchContext } from "contexts/search";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { FilterBar } from "@/components/tools/filter_bar";
import { dateParse } from "utils/locale";
import { SlideOver } from 'components/core/slide_over';
import { Button } from "@/components/ui/button";
import { CreateAADExclusionDate } from "@/components/aad-dates/create";
import { ModifyAADExclusionDate } from "@/components/aad-dates/modify";

export function AADDatesList() {
    return (
        <>
            <SearchContextProvider
                doNotStore
                defaultState={{ orderBy: "from_date", orderDirection: "desc" }}>
                <AADDatesListWithContext />
            </SearchContextProvider>
        </>
    );
}


function AADDatesListWithContext() {
    const { t } = useTranslation("common");
    const { filters, dispatchFilters } = useContext(SearchContext);
    const [date, setDate] = useState(null);
    const params = useMemo(() => ({ ...filters }), [filters]);
    const [exclusions, { loading, error, paging, page, setPage, execute, setResult:setExclusions }] = useSearch(searchStudySessionExclusions, params, { camelize: true });

    const events = useMemo(() => {
        return [...(exclusions || [])]
            .sort((a, b) => dateParse(a.fromDate) - dateParse(b.fromDate))
            .map((d, i) => ({
                fromDate: dateParse(d.fromDate),
                toDate: dateParse(d.toDate),
                blue: i % 2 === 0,
            }));
    }, [exclusions]);

    const headers = useMemo(() => {
        const value = [
            {
                title: t("start"),
                className: "text-left",
                field: (d) => (d ? d.fromDate : "from_date"),
                itemClassName: "py-2 whitespace-nowrap",
            },
            {
                title: t("end"),
                field: (d) => (d ? d.toDate : "to_date"),
                itemClassName: "py-2 whitespace-nowrap",
            },
            {
                title: t("student"),
                field: (d) => (d ? d : "student"),
                itemClassName: "",
                className: "whitespace-nowrap",
                FormatComponent: (d) => (
                    <div className="py-2 whitespace-nowrap">
                        {d?.student?.name}
                    </div>
                ),
            },
            {
                title: t("school"),
                field: (d) => (d ? d : "school"),
                itemClassName: "",
                FormatComponent: (d) => (
                    <div className="py-2 whitespace-nowrap">
                        {d?.school?.shortName}
                    </div>
                ),
            },
            {
                title: t("description"),
                field: (d => d ? d.description : "description"),
                parseNull: true,
                format: (d => d ? d : "-"),
                itemClassName: "py-2 text-xs"
            },
            {
                headerId: "modify",
                field: (d => d ? ({
                    value: d,
                    onDelete: ((d => setExclusions(current => current.filter(e => e.id !== d.id)))),
                    setValue: ((next) => setExclusions(current => current.map(e => e.id === next.id ? next : e))),
                }) : "Modifier"),
                FormatComponent: ModifyButton
            },
        ];
        return value;
    }, [exclusions, loading]);

    if (!exclusions) {
        return (
            <Skeleton.List
                numElements={1}
                itemClassName="h-8"
                className="space-y-2"
            />
        );
    }

    return (
        <Info.Container modify={true} sideHeader={<Fragment><CreateButton onResult={execute} /></Fragment>}>
            <div className="w-full max-w-2xl mx-auto">
                <DoubleMonthView
                    date={date}
                    onDateChange={setDate}
                    layout="side"
                    numMonths={2}
                    intervals={events}
                />
            </div>
            <FilterBar school student color="gray" noMargin />
            {exclusions.length === 0 ? (
                <EmptyState
                    className="bg-gray-100 rounded-md"
                    title={t("empty-state.no-aad-exclusion-dates-title")}
                />
            ) : (
                <>
                    <ScrollArea>
                        <Table
                            headers={headers}
                            data={exclusions}
                            indexingKey="id"
                            order={{
                                by: filters?.orderBy,
                                direction: filters?.orderDirection,
                            }}
                            onHeaderClick={(value) =>
                                dispatchFilters({ type: "ordering", value })
                            }
                        />
                        <ScrollBar orientation="horizontal" />
                    </ScrollArea>
                </>
            )}
            {paging && <Paginate page={page} setPage={setPage} {...paging} />}
            {error ? <Error.Text {...error} /> : null}
        </Info.Container>
    );
}


function CreateButton({ onResult }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button variant="active" onClick={() => setOpen(true)} color="active">{t("aad-exclusion-date.create.btn")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateAADExclusionDate onResult={() => { setOpen(false); onResult(); }} />
        </SlideOver>
    </Fragment>
}

function ModifyButton({ value, setValue, onDelete }) {
    const { t } = useTranslation('common');
    const [open, setOpen] = useState(false);
    return <Fragment>
        <Button onClick={() => setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
        <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyAADExclusionDate value={value} setValue={(v)=>{setOpen(false); setValue?.(v)}} onDelete={() => { setOpen(false); onDelete?.(value) }} />
        </SlideOver>
    </Fragment>
}