import React, { useEffect, useMemo, useState } from 'react';

import {QRCodeSVG} from 'qrcode.react';
import { KeyRound, LockKeyhole, ShieldPlus } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "@/components/ui/dialog"
import { useTranslation } from 'react-i18next';
import { useAPI } from '@/hooks/useAPI';
import { deactivateTOTP, generateTOTPUri, validateTOTP } from '@/api/auth';
import { Skeleton } from '@/components/core/skeleton';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp"
import { REGEXP_ONLY_DIGITS } from 'input-otp';
import { Button } from '@/components/ui/button';
import { useUserV2 } from '@/hooks/useUser';

function DeactivateTOTPDialog(){
  const {t} = useTranslation("auth");
  const [code, setCode] = useState("");
  const {setUser} = useUserV2();
  const [answer, {execute: deactivate}] = useAPI(deactivateTOTP, {token:code}, {immediate: false});
  const [open, setOpen] = useState(false);
  useEffect(()=>{
    setCode("");
  }, [open]);
  useEffect(()=>{
    if (answer && answer.success){
      setUser(data=>({...data, totp_enabled: false}));
    }
  }, [answer]);
  return <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger asChild>
      <Button variant="ghost" size="md" onClick={()=>setOpen(true)}>
        {t("security.setup-totp.deactivate.button")}
      </Button>
    </DialogTrigger>
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t("security.setup-totp.deactivate.title")}</DialogTitle>
        {answer && answer.success?
          <DialogDescription>{t("security.setup-totp.deactivate.success")}</DialogDescription>:
          <>
          <DialogDescription>{t("security.setup-totp.deactivate.help")}</DialogDescription>
          <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS} onChange={setCode} value={code}>
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
            </InputOTPGroup>
            <InputOTPSeparator />
            <InputOTPGroup>
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
          <div className="mt-4 flex justify-end gap-x-5">
            <Button variant="ghost" size="md" onClick={()=>setOpen(false)}>{t("security.setup-totp.deactivate.cancel")}</Button>
            <Button variant="default" 
            disabled={code.length !== 6}
            size="md" onClick={()=>deactivate()}>{t("security.setup-totp.deactivate.confirm")}</Button>
          </div>
        </>}
      </DialogHeader>
    </DialogContent>
  </Dialog>
}

function TOTPEnabled(){
    const {t} = useTranslation("auth");
  const {user} = useUserV2();

    return <Alert className='flex items-center justify-between gap-x-3 pt-4'>
                  <LockKeyhole />
                  <div>
                    <AlertTitle>{t("security.setup-totp.enabled.title")}</AlertTitle>
                    <AlertDescription>{t("security.setup-totp.enabled.help")}
                    </AlertDescription>
                    {user.totp_enabled && 
                    <div className='border-l-2 border-green-500 pl-3 mt-2'>
                      <p className=''>{t("security.setup-totp.is-active")}</p>
                    </div>
                    }
                  </div>
                <DeactivateTOTPDialog/>
            </Alert>
}

function TOTPDisabled(){
  const {t} = useTranslation("auth");
  const params = useMemo(()=>({}), []);
  const [code, setCode] = useState("");
  const {setUser} = useUserV2();
  const [res, {execute: askUri}] = useAPI(generateTOTPUri, params, {immediate: false});
  const [validationResponse, {execute: validate, loading, error}] = useAPI(validateTOTP, {token:code}, {immediate: false});

    return <Dialog 
    onOpenChange={(open)=>{
      if (open){
        askUri();
      }
      else {
        if (validationResponse && validationResponse.success){
          setUser(data => ({ ...data, totp_enabled: true }));
        }
      }
    }}>
    <DialogTrigger asChild >
      <Alert variant={"default"} className='flex items-center justify-between gap-x-3 pt-4' asChild>
        <KeyRound />
        <div className='text-left'>
          <AlertTitle>{t("security.setup-totp.disabled.title")}</AlertTitle>
          <AlertDescription>{t("security.setup-totp.disabled.help")}
          </AlertDescription>
        </div>
        <Button variant="default" size="md" >
          <ShieldPlus />
          {t("security.setup-totp.disabled.button")}</Button>
      </Alert>
    </DialogTrigger>
      <DialogContent  className='overflow-auto max-h-[95vh]' >
      {/* <ScrollArea className='overflow-auto max-h-[80vh]'> */}
      
      <DialogHeader >
        <DialogTitle>{t("security.setup-totp.title")}</DialogTitle>
        
        {validationResponse && validationResponse.success?
          <DialogDescription>{t("security.setup-totp.success")}</DialogDescription>:
          <>
            <DialogDescription>
              {t("security.setup-totp.help")}

              <ol className='list-decimal list-outside pl-6 py-2 space-y-1'>
                <li>{t("security.setup-totp.steps.step1")}</li>
                <li>{t("security.setup-totp.steps.step2")}</li>
                <li>{t("security.setup-totp.steps.step3")}</li>
              </ol>
            </DialogDescription>
            
           

            {!res || !res.uri?
              <Skeleton className={"h-96"} />:
              <div className='py-5 flex items-center justify-center'>
                  <QRCodeSVG value={res.uri} height={200} width={200}/>
              </div>
            }
            <div className='border rounded-md p-3 '>
              <form  onSubmit={(e)=>{
                e.preventDefault();
                validate();
              }
              }>
                <label className="block text-sm font-medium text-gray-700">{t("security.setup-totp.enter-code.title")}</label>
                <p className="block text-sm text-gray-500">{t("security.setup-totp.enter-code.help")}</p>
                <div className='container lg:flex items-center justify-center lg:justify-between mt-2 space-y-4 lg:space-y-0'>
                    <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS} onChange={setCode} value={code}>
                    <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                  </InputOTP>
                  <Button variant="default" 
                  loading={loading}
                  disabled={loading || code.length !== 6}
                  size="md" 
                  type='submit'>{t("security.setup-totp.enter-code.validate")}</Button>
                </div>
                {error && <p className="text-red-500 text-sm mt-3">{t(error.code, {ns: 'errors', ...error.payload})}</p>}

            </form>
            </div>
            </>
        }
      </DialogHeader>
      {/* </ScrollArea> */}
    </DialogContent>
  </Dialog>
}  
export function SetupTOTP(){
    const {user} = useUserV2();
    if (user.totp_enabled){
        return <TOTPEnabled/>
    }
    return <TOTPDisabled/>
}

 
export function SetupTOTPBanner(){
  const {user} = useUserV2();
  if (user.totp_enabled){
      return null
  }
  return <div className="p-4 bg-slate-100 border-b"><TOTPDisabled/></div>
}