import React from 'react';

// Components
import { DashboardLayout, MultiColumn } from 'components/core/layouts';
import { Container } from 'components/core/container';
import { CreateNote } from 'components/notes/create';
import classNames from 'classnames';
import { NoteHistoryCells } from '@/components/notes/note-history';
import { useLocalNotes } from '@/contexts/note-storage';
import { Skeleton } from '@/components/ui/skeleton';

export function CreateNotePage(){
  const ni = 0;
    const {loadingSelectedNote, selectedNote} = useLocalNotes();
  
  return <DashboardLayout>
          <MultiColumn>
              <MultiColumn.Left className={classNames("border-r", ni && "hidden md:block")}>
                <NoteHistoryCells/>
              </MultiColumn.Left>
              <MultiColumn.Content  className={classNames(!ni && "hidden md:block")}>
              <Container className="py-6">
                {loadingSelectedNote?
                <Skeleton className="w-full h-[200px] rounded-md"/>:
                <CreateNote 
                defaultLocalNoteId={selectedNote?.id}
                defaultStudent={selectedNote?.student}
                defaultTutor={selectedNote?.tutor}
                defaultPairing={selectedNote?.pairing}
                defaultStudySession={selectedNote?.studySession}
                defaultGroup={selectedNote?.group}
                defaultMinutes={selectedNote?.minutes}
                defaultOpenedAt={selectedNote?.openedAt? new Date(selectedNote?.openedAt): null}
                defaultClosedAt={selectedNote?.closedAt? new Date(selectedNote?.closedAt): null}
                defaultCommentTypes={selectedNote?.commentTypes}
                defaultPeriod={selectedNote?.period}
                defaultCommentDisplayDate={selectedNote?.commentDisplayDate? new Date(selectedNote?.commentDisplayDate): null}
                defaultCode={selectedNote?.code}
                defaultComment={selectedNote?.content}/>
                }
              </Container> 
              </MultiColumn.Content>
            </MultiColumn>
          
         </DashboardLayout>
}

const route =  { path: "/new-note", 
                 name: "Nouvelle note au dossier" , 
                 requireAuth: true, 
                 Element: CreateNotePage };
export default route;
