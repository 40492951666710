import React from 'react';
import { SideBarLayout } from 'components/core/layouts/sidebar';
import { StackedLayout } from 'components/core/layouts/stacked';
import { Nav, ExportToCSVDropdown, SettingsDropdown, AddShortcutDropdown, LocalNote } from 'components/core/nav';
import { Toaster } from 'react-hot-toast';
export function SideDashboardLayout({ children, contentClassName }){
  return <div className="h-full">

          <SideBarLayout.Main>
              <SideBarLayout.Nav>
                <Nav side/>
              </SideBarLayout.Nav>
            <SideBarLayout.TopBar>
              <SideBarLayout.MobileNav>
                <Nav side/>
              </SideBarLayout.MobileNav>
              <ExportToCSVDropdown className="hidden lg:block ml-auto"/>
              <LocalNote className="hidden lg:block"/>
              <AddShortcutDropdown className="ml-auto lg:mx-0"/>
              <SettingsDropdown className="ml-3"/>
            </SideBarLayout.TopBar>
            <Toaster position='bottom-right'/>
            <SideBarLayout.Content className={contentClassName}>
            {children}
            </SideBarLayout.Content>
          </SideBarLayout.Main>
         </div>
}

export function StackedDashboardLayout({ children }){
  return <div className="h-full">
          <StackedLayout.Main>
             <StackedLayout.Nav>
                <Nav/>
              </StackedLayout.Nav>
            <StackedLayout.Content>
              {children}
            </StackedLayout.Content>
          </StackedLayout.Main>
         </div>
}

export const DashboardLayout = SideDashboardLayout;