import React, { useState } from 'react';

// Hooks
import { useLocation, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from 'hooks/useUser';

// Components
import { Button, colors } from 'components/core/button';
import { CreateNoteInModal } from 'components/notes/create_modal';
import { Dropdown } from 'components/core/dropdown';
import { FiPlus, FiUsers, FiHome, FiClipboard, FiDribbble, FiClock, FiZap, FiGitMerge, FiRadio, FiLock, FiFolderPlus, FiLogOut, FiShuffle, FiDownload, FiCalendar, FiLayout } from 'react-icons/fi';

// Data

// import {default as students} from 'pages/students'
// import {default as coachs} from 'pages/coachs'
// import {default as teams} from 'pages/teams'
// import {default as study_sessions} from 'pages/study_sessions'
// import {default as tutors} from 'pages/tutors'

// import {default as pairings} from 'pages/pairings'
// import {default as new_note} from 'pages/new_note'
// import {default as new_study_session} from 'pages/new_study_session'
// import {default as new_team} from 'pages/new_team'
// import {default as new_tutor} from 'pages/new_tutor'
// import {default as new_student} from 'pages/new_student'
// import {default as new_coach} from 'pages/new_coach'

// import {default as weekly_follow_up} from 'pages/weekly_follow_up'
// import {default as tutoring_follow_up} from 'pages/tutoring_follow_up'
// import {default as export_page} from 'pages/export'
// import {default as auto_pairing} from 'pages/auto-pairing/index'
// import {default as end_year_assistant} from 'pages/end-year-assistant/index'
// import {default as recruting} from 'pages/recruting/index'
// import {default as portal_access} from 'pages/portal-access/index'
// import {default as bulk_import} from 'pages/bulk-import/index'
// import {default as sms_challenge} from 'pages/sms_challenge'


// Utils
import classnames from 'classnames';
import { FolderUp, NotebookPen } from 'lucide-react';
import { useLocalNotes } from '@/contexts/note-storage';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';

function NavItem({ path, Icon, name, side}){
  const match = useMatch(path+"/*");
  const active = !!match;
  return <Button color={active?(side? "activeSideNav": "activeNav"):(side?"sideNav":"nav")}
              size={side?"sideNav": "nav" }
              href={path}
              className="relative w-full whitespace-nowrap overscroll-auto">
                  <Icon className="absolute w-6"/>
                  <span className={classnames("ml-10", side? "transition-all opacity-100 group-hover:opacity-100 2xl:opacity-100 sm:opacity-100 md:opacity-0": "")}>{name}</span>
              </Button>
}

export function SideNavLink({ path, Icon, name }){
  return <Button color={(active)=>active?"activeSideNav":"sideNav"}
                size="sideNav"
                layout="none"
                nav
                href={path||"#"}>
                {Icon?<Icon/>:null} <span className="ml-2">{name}</span></Button>
}


export function SubSideNavLink({ path, Icon, name, textColor}){
  return <Button color={(active)=>active?"activeSubSideNav":"subSideNav"}
                size="subSideNav"
                layout="none"
                nav
                end={true}
                href={path||"#"}>
                {Icon?<Icon className={`h-4 ${textColor}`}/>:null} <span className={`ml-2 ${textColor}`}>{name}</span></Button>
}


export function SubSideNavLinkState({ value, active, setValue, Icon, name, textColor}){
  return <Button color={active?"activeSubSideNav":"subSideNav"}
                size="subSideNav"
                layout="none"
                onClick={()=>setValue(value)}>
                {Icon?<Icon className={`h-4 ${textColor}`}/>:null} 
                <span className={`ml-2 ${textColor}`}>{name}</span></Button>
}

export function SettingsDropdown({ className} ){
  const [user] = useUser(true);
  const { t } = useTranslation('common');
  return  <div className={classnames("ml-5 ", className)}>
            <Dropdown
              orientation="right"
              customButton={t("nav.settings")}
              customButtonClassName={classnames("flex items-center", colors.nav)}
              menuClassName="md:relative whitespace-nowrap ">
              <Dropdown.Info title={`${user.firstname} ${user.lastname}`} description={`${user.role|| "Aucune rôle"}`} className="test"/>
              <Dropdown.Border/>
              <Dropdown.Item href="/settings/profile" name={t('nav.account-settings')} color="default"/>
              <Dropdown.Item href="/changelog" name={t('nav.changelog')} color="default"/>
              <Dropdown.Border/>
              <Dropdown.Item href="/logout" name={t('logout')} color="danger"/>
            </Dropdown>
          </div>
}

export function AddShortcutDropdown({ className}){
  const { t } = useTranslation('common');
  const [showAddNote, setShowAddNote] = useState(false);
  const location = useLocation();
  return  <div className={classnames(className)}>
            <Dropdown
              onlyIcon={<FiPlus/>}
              withChevron
              customButtonClassName={classnames("flex items-center", colors.nav)}
              menuClassName="md:relative whitespace-nowrap">
              {location.pathname==="/new-note"?null:
              <Dropdown.Item onClick={()=>setShowAddNote(true)} name={t('new-note')} color="default"/>}
              <Dropdown.Item href="/new-study-session" name={t('new-study-session')} color="default"/>
              <Dropdown.Border/>
              <Dropdown.Item href="/new-student" name={t('new-student')} color="default"/>
              <Dropdown.Item href="/new-tutor" name={t('new-tutor')} color="default"/>
              <Dropdown.Item href="/new-team" name={t('new-team')} color="default"/>
              <Dropdown.Item href="/new-coach" name={t('new-coach')} color="default"/>
            </Dropdown>
            <CreateNoteInModal open={showAddNote} setOpen={setShowAddNote}/>

          </div>
}


export function ExportToCSVDropdown({ className}){
  return  <div className={classnames(className)}>
            <Button
             href="/export"
             size="toolbarIcon"
             color="gray">
              <FolderUp/>
            </Button>
          </div>
}

export function LocalNote({ className}){
  const {notes} = useLocalNotes();
  const {t} = useTranslation('common');
  return  <div className={classnames(className)}>
            <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Button
                      href="/new-note"
                      size="toolbarIcon"
                      className='relative'
                      color="gray">
                        <NotebookPen/>
                        {notes.length?
                        <span className='bg-red-500 text-[60%] h-4 rounded-full flex items-center justify-center px-1.5 py-0 absolute top-0 right-0 text-white'>{notes.length}</span>:
                        null}
                      </Button></TooltipTrigger>
              <TooltipContent>
                <p>{t("num-local-notes", {count: notes.length})}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          </div>
}

export function Nav({ side }){

  return <div className={side?"bg-slate-800": "px-3 py-6 md:p-0 h-full md:flex items-center w-full overflow-hidden group"}>
          <nav className={side?"mt-6": "mt-8 md:space-y-0 md:flex items-center h-full md:ml-auto "}>
            <NavItem path="/home" name="Vue d'ensemble" side={side} Icon={FiHome}/>
            <NavItem path="/students" name="Élèves-athlètes" side={side} Icon={FiUsers}/>
            <NavItem path="/coachs" name="Entraîneurs" side={side} Icon={FiClipboard}/>
            <NavItem path="/teams" name="Équipes" side={side} Icon={FiDribbble}/>
            <NavItem path="/study-sessions" name="Aides aux devoirs" side={side} Icon={FiClock}/>
            <NavItem path="/tutors" name="Tuteurs bénévoles" side={side} Icon={FiZap}/>
            <NavItem path="/pairings" name="Jumelages" side={side} Icon={FiGitMerge}/>
          </nav>

          <div className="mt-6 md:mt-12 ">
            <div className="invisible px-3 text-xs tracking-wider text-gray-400 uppercase 2xl:block group-hover:visible 2xl:visible">Suivi</div>
            <nav className={side?"": " md:space-y-0 md:flex items-center h-full md:ml-auto "}>
              <NavItem path="/suivi-terrain" name="Terrain" side={side} Icon={FiCalendar}/>
              <NavItem path="/tutoring-follow-up" name="Tutorat" side={side} Icon={FiLayout}/>
            </nav>
          </div>

          <div className="mt-6 md:mt-12 ">
            <div className="invisible px-3 text-xs tracking-wider text-gray-400 uppercase 2xl:block group-hover:visible 2xl:visible">Outils</div>
            <nav className={side?"": " md:space-y-0 md:flex items-center h-full md:ml-auto "}>
              <NavItem path="/export/students" name="Exportation" side={side} Icon={FiDownload}/>
              <NavItem path="/automatic-pairing" name="Jumelage automatique" side={side} Icon={FiShuffle}/>
              <NavItem path="/end-year-assistant" name="Fermeture d'année" side={side} Icon={FiLogOut}/>
              <NavItem path="/bulk-import/students" name="Importation" side={side} Icon={FiFolderPlus}/>
            </nav>
          </div>

          <div className="mt-6 md:mt-12 ">
            <div className="invisible px-3 text-xs tracking-wider text-gray-400 uppercase 2xl:block group-hover:visible 2xl:visible">Portail</div>
            <nav className={side?"": " md:space-y-0 md:flex items-center h-full md:ml-auto "}>
              <NavItem path="/recruiting" name="Recrutement" side={side} Icon={FiRadio}/>
              <NavItem path="/portal-access" name="Accès" side={side} Icon={FiLock}/>
            </nav>
          </div>

          {side? null: <div className="h-6 border-l"/>}
          {side? null: <LocalNote/>}
          {side? null: <ExportToCSVDropdown/>}
          {side? null: <AddShortcutDropdown/>}
          {side? null: <SettingsDropdown/>}
         </div>
}
