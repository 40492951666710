import React from 'react';

// Component
import { Loading } from 'pages/loading';
import { Error401 } from 'pages/errors/401';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import { InactivityGuard } from 'components/auth';


// Hooks
import { useIsConnected } from 'hooks/useIsConnected';
import { NoteLocalStorageProvider } from '@/contexts/note-storage';

export function AuthManager({scopes}){
  let location = useLocation();
  const storedIsConnected = useIsConnected();
  const [user ] = useUser();
  const isConnected = user && user.email;
  // On / path, redirect to /home if connected, /login if not
  console.log(storedIsConnected, user)
  if (location.pathname==="/"){
    if (storedIsConnected){
      return <Navigate to={"/home"} state={location.state} />
    }
    else if (storedIsConnected===false){
      return <Navigate to={`/login?ref=${encodeURIComponent(window.location.pathname+window.location.search)}`}/>
    }
    return <Loading/>
  }

  // On other direct paths, redirect to /login if not connected
  if (!isConnected && (!location.state || !location.state.email)){
    return <Navigate to={`/login?ref=${encodeURIComponent(window.location.pathname+window.location.search)}`}/>
  }
  if (isConnected || (location.state && location.state.email)){
    if (scopes && !scopes.find(d=>d===((user && user.role) ||(location.state && location.state.role)))){
      return <Error401 scopes={scopes}/>
    }
    return <InactivityGuard><NoteLocalStorageProvider><Outlet/></NoteLocalStorageProvider></InactivityGuard>;
  }
  return <Loading/>
}
