import React from 'react';

// Hooks
import { useState, useCallback, useEffect, useMemo, Fragment } from 'react'
import { useDebounce } from 'hooks/useDebounce';

// Components
import { FiInfo, FiLock } from 'react-icons/fi'
import { HiSelector } from 'react-icons/hi'
import { Spinner } from 'components/core/spinner';
import { Combobox } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

// Utils
import classnames from 'classnames';
import { Tooltip } from 'components/core/tooltip';

const styles = {
    default: {
        button: 'border-none bg-gray-100 disabled:bg-gray-200 disabled:text-gray-900 font-medium text-gray-900 whitespace-nowrap hover:bg-gray-100 sm:px-3',
        iconActive: 'text-gray-900 ',
        iconInactive: 'text-gray-300 ',
    },
    white: {
        button: 'border-none bg-white shadow disabled:bg-gray-200 disabled:text-gray-800 font-medium text-gray-900 whitespace-nowrap sm:px-3',
        iconActive: 'text-gray-900 ',
        iconInactive: 'text-gray-300 ',
    },
    orangeOutline: {
        button: 'ring-2 border-transparent ring-orange-500 bg-white disabled:bg-gray-200 disabled:text-gray-800 font-medium text-gray-900 whitespace-nowrap sm:px-3',
        iconActive: 'text-orange-500 ',
        iconInactive: 'text-gray-300 ',
    },
    transparent: {
        button: 'border-none bg-transparent disabled:bg-transparent disabled:text-gray-800 font-medium text-gray-900 whitespace-nowrap sm:px-3',
        iconActive: 'text-gray-900 ',
        iconInactive: 'text-gray-300 ',
    },
    gray: {
        button: 'bg-gray-100 border-none disabled:bg-gray-50 disabled:opacity-70 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 py-2 px-2',
        iconActive: 'text-gray-900 ',
        iconInactive: 'text-gray-300 ',
    }
}

const sizes = {
    none: {
        width: ""
    },
    xs: {
        width: "w-full max-w-xs"
    },
    sm: {
        text: 'text-sm',
        button: 'py-1.5 md:py-2 pl-3 pr-10 sm:text-sm ',
        width: "w-80 max-w-sm"
    },
    smWider: {
        text: 'text-sm',
        button: 'py-1.5 md:py-2 pl-3 pr-10 sm:text-sm w-96 ',
        width: "w-full max-w-md"
    },
    md: {
        text: 'text-base',
        button: 'py-2 md:py-3 pl-3 pr-10 text-base ',
        width: "w-full max-w-md"
    },
    lg: {
        width: "w-screen  max-w-lg"
    },
    xl: {
        width: "w-screen  max-w-lg ",
        button: 'text-2xl font-medium text-gray-800 !px-1 !py-0 mt-0.5'
    },
    full: {
        width: "w-full"
    },
    flexfull: {
        width: "w-full flex-1",
        button: 'flex-1'
    }
}

export function SearchField({ label, // Optional label
    values, // List of search results
    loading,
    description,
    value, // Selected value
    setValue, // Set selected value
    disabled,
    color = "default",
    size = "sm",
    info,
    bgInherit = false,
    targetWidth,
    vOrientation,
    placeholder,
    indexingField,
    to,
    by,
    multiple = false,
    inputClassName,
    formatSelectedValue, // Method that returns the selected value as string
    formatSearchResult, // Method that renders a search result
    optionsClassName,
    onParamsChange }) {
    const { t } = useTranslation('common');
    const [search, setSearchQuery] = useState();
    const debounceSearch = useDebounce(search, 300);
    const params = useMemo(() => ({ search: debounceSearch }), [debounceSearch]);

    useEffect(() => {
        if (params !== null && params.search != null) {
            onParamsChange?.(d => ({ ...d, ...params }));
        }
    }, [params]);

    const handleUpdate = useCallback((d) => {
        if (value && value[indexingField] === d[indexingField]) {
            setValue(d);
        }
        else {
            if (d[indexingField]) {
                setValue(d);
            }
            else {
                setValue(null);
            }
        }
    }, [value])

    return (
        <Combobox multiple={multiple} by={by} disabled={disabled} as="div" value={value} onChange={multiple ? setValue : handleUpdate} className={classnames(targetWidth && sizes[targetWidth].width)}>
            {label ? <Combobox.Label className={classnames("block font-medium text-gray-700 text-xs ml-1", sizes[size].text)}>{label}</Combobox.Label> : null}
            <div className="relative ">
                <Combobox.Input
                    className={classnames("w-full rounded-md focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500", sizes[size].button, styles[color].button, bgInherit && "bg-inherit hover:bg-inherit", inputClassName)}
                    onChange={(event) => setSearchQuery(event.target.value)}
                    placeholder={placeholder}
                    displayValue={value != null ? formatSelectedValue : (() => "")}
                />
                {description ? <Combobox.Label className={classnames("block text-gray-700 text-xs ml-1 mt-1", sizes[size].text)}>{description}</Combobox.Label> : null}
                <Combobox.Button className={classnames("absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none", description && "mb-4 ")}>
                    {loading ? <Spinner size="xs" className="w-5 h-5 text-gray-400" /> :
                        disabled ? <FiLock className="w-4 h-4 text-gray-400" aria-hidden="true" /> :
                            <HiSelector className="w-5 h-5 text-gray-400" aria-hidden="true" />}
                    {info && <Tooltip content={info} className="ml-2"><span><FiInfo className='z-50 text-gray-400' /></span></Tooltip>}
                </Combobox.Button>
                <Combobox.Options className={classnames(optionsClassName, "absolute z-20 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-52 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
                    vOrientation === "top" && "-translate-y-[calc(100%+50px)]")}>
                    {!values ?
                        <div className="py-2 pl-3 pr-9">{t("loading")}</div> :
                        values.length === 0 ?
                            <div className="py-2 pl-3 pr-9">{t("nothing-found")}</div> :

                            <Fragment>
                                {value && value[indexingField] && <Combobox.Option
                                    key={"__clear__"}
                                    value={{}}
                                    as={to ? Link : "li"}
                                    to={to ? to() : null}
                                    className={() =>
                                        classnames(
                                            'relative cursor-pointer select-none py-1 pl-3 pr-3 hover:bg-gray-100 ',
                                            'text-red-500 text-sm',
                                            to && "block"
                                        )
                                    }
                                >
                                    {() => (
                                        <>
                                            <span>{t("clear-selection-2")}</span>
                                        </>
                                    )}
                                </Combobox.Option>}


                                {values.map((result) => (
                                    <Combobox.Option
                                        key={result[indexingField]}
                                        value={result}
                                        as={to ? Link : "li"}
                                        to={to ? to(result) : null}
                                        className={({ active, selected }) =>
                                            classnames(
                                                'relative cursor-pointer select-none py-2 pl-2 pr-2',
                                                (!multiple) && (active || selected) ? 'bg-orange-600 text-white' :
                                                    multiple && (!active && selected) ? 'bg-gray-100' :
                                                        multiple && (active && !selected) ? 'bg-gray-100' :
                                                            multiple && (active && selected) ? 'bg-gray-200' :
                                                                multiple && (selected) ? 'bg-gray-100' :
                                                                    'text-gray-900',
                                                to && "block"
                                            )
                                        }
                                    >
                                        {({ active, selected }) => (
                                            <>
                                                {formatSearchResult?.(result, { selected, active })}
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))}
                            </Fragment>
                    }
                </Combobox.Options>

            </div>
        </Combobox>
    )
}
