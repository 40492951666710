import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback, useState, useEffect, useContext } from 'react';
import { useMonth } from 'hooks/dates/useMonth';
import { useSearch } from 'hooks/useSearch';
import { useTextTruncate } from 'hooks/useTextTruncate';
import { useQuery } from 'hooks/useQuery';

// Components
import { FilterBar } from 'components/notes/filter_bar';
import { Fragment } from 'react';
import { Skeleton } from 'components/core/skeleton';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo';
import { Paginate } from 'components/core/paginate';
import { SlideOver } from 'components/core/slide_over';
import { NoteExtendedViewQueryBased } from 'components/notes/card';
import { Button } from 'components/core/button';
import { FiX, FiMessageCircle } from 'react-icons/fi'
import { Viewer } from 'components/core/rich-editor/viewer';

// Contexts
import { SearchNotesContext, SearchNotesContextProvider } from 'contexts/search_notes';

// Utils
import { dateParse, locale, parseWithTimeZone } from 'utils/locale';
import classnames from 'classnames';
import { datesAreInInterval }  from 'utils/date_compare';
import { timeDay } from 'd3-time';

// API
import { searchNotes } from 'api/notes';
import { Badge } from '../ui/badge';

export function Empty(){
  const {filters} = useContext(SearchNotesContext);
  const { t } = useTranslation('common');

  const msg = useMemo(()=>{
    var m = "";
    if (filters.student) m+=("EA: "+filters.student.name);
    if (filters.preset) m+=("Favori: "+filters.preset.name);
    if (filters.team) m+=("Équipe: "+filters.team.name);
    return m;
  }, [filters])

  return <EmptyState
            className="flex flex-col justify-center h-full"
            title={t("empty-state.no-notes-title")}
            description={t("empty-state.no-notes-description", {filters: msg})}>
         </EmptyState>
}

// Utils
function Comment({content, stackedComments, closed_at, hideExpand=false}){
  const {t} = useTranslation('common')
  const [truncatedContent, isTruncated] = useTextTruncate(content, {length:450});
  const [expand, setExpand] = useState(false);
  const showButton = !hideExpand && isTruncated && !expand;
  const showLess = !hideExpand && isTruncated && expand;
  if (!content) return null;
  return  <div className={classnames('pt-1', !showButton? "pb-0": stackedComments>2? 'pb-2': stackedComments>1? 'pb-3': 'pb-0')}>
            <div className={classnames("relative", stackedComments>2? 'mb-2': stackedComments>1? 'mb-1': '' )}>
            {stackedComments>2 && <div className={classnames("absolute top-3   rounded-md inset-x-4 h-full", !closed_at? "bg-gray-100 !rounded-l-none rounded-r-md border-l-4 border-yellow-500": "bg-gray-100")}></div>}
            {stackedComments>1 && <div className={classnames("absolute top-1.5   rounded-md inset-x-2 h-full", !closed_at? "bg-gray-100 !rounded-l-none rounded-r-md border-l-4 border-yellow-500": "bg-gray-100")}></div>}
              <div className={classnames("rounded-md relative p-2 z-0", stackedComments>0 && "", !closed_at? "bg-gray-100 !rounded-l-none rounded-r-md border-l-4 border-yellow-500": "bg-gray-100", !stackedComments&& "text-gray-500")}>
                {expand? <Viewer html={content}/>  : <Viewer html={truncatedContent}/>}
                <div className='flex justify-end'>
                  {showButton && <Button className="mt-2" color="link" size="xs" onClick={(e)=>{e.stopPropagation(); setExpand(true)}}>{t("show-more-notes")}</Button>}
                  {showLess && <Button className="mt-2" color="link" size="xs" onClick={(e)=>{e.stopPropagation(); setExpand(false)}}>{t("show-less-notes")}</Button>}
                </div>
              </div>
            </div>
          </div>
}

export function CompactNoteCell({code, comments, opened_at, closed_at, select, student, should_discuss}){
  return <div className={classnames("flex group  p-1 ")} >
             <div className=" items-center block w-full p-3 overflow-visible rounded-lg ring-1 ring-gray-200 hover:ring-2 hover:ring-orange-500">
               <div className="w-full h-full" >

                  <div className="items-start justify-between sm:flex">
                    <div className="flex items-center">
                        <span className="flex-shrink-0 inline-block w-4 h-4 mr-2 rounded-md" style={{background:code.color}}/>
                        <div>
                          <p className="flex items-center text-xs text-gray-600 uppercase font-base">{code.group}</p>
                          <div><Button className="block font-medium hover:underline text-left" onClick={select}>{code.name}</Button> </div>
                          {student && <Button color="baseLink" className="block" href={`/students/${student.ni}`}>{student.name}</Button>}
                        </div>
                    </div>
                    <div className="flex flex-col items-end justify-between">
                      <div className="flex items-center space-x-3">
                        {should_discuss &&
                          <div  className="p-1 text-xl text-pink-500 shadow bg-pink-50 rounded-xl"><FiMessageCircle/></div>}
                      </div>
                      <div className="flex-shrink-0 ml-3 text-sm text-right text-gray-500">{locale.format("%d %B %Y à %H:%M")(comments.length===0? parseWithTimeZone(opened_at): parseWithTimeZone(comments[0].created_at) )}</div>
                    </div>
                  </div>

                  <div className="cursor-pointer hover:opacity-80" onClick={select}>
                    {
                      comments.slice(0,1).map(d=><Comment key={d.id} author={d.author} content={d.content} closed_at={closed_at} stackedComments={comments.length}/>)
                    }
                  {comments.length===0 && <Comment  closed_at={closed_at} />}

                  </div>
               </div>
            </div>
          </div>
}

export function NoteCellWeeklyView({code, student, comments, opened_at, closed_at, noBorder,  highlighted, open, select, className, reason}){
  const {t} = useTranslation('common')
  return <div className={classnames("flex group")} >
             <button className={classnames("flex items-center w-full bg-white rounded-lg p-2  text-left hover:border-2 hover:border-orange-500 hover:bg-gray-100", 
             !noBorder && "border", 
             highlighted?"border-2 border-blue-500 bg-blue-200":
             open?"border-2 border-green-500": "border-2 border-gray-100",
             className)}  onClick={select}>
               <div className="w-full h-full" >
                  <div className="w-full">
                    {reason==="update" && <Badge variant={reason==="open"? "green": "red"}  className="mr-2">{t(reason)}</Badge>}
                    <div className="flex items-end w-full mt-2">
                        <div className="w-full">
                          <div className="flex items-center justify-between ">
                            {student && <p className="text-sm ">{student.name}</p>}
                            <span className="flex-shrink-0 inline-block w-4 h-4 rounded-md " style={{background:code && code.color}}/>
                          </div>
                          <div className="flex items-center justify-between ">
                            <span className="flex items-center text-xs text-gray-400 uppercase font-base">{code && code.group}</span>
                            <div className="flex-shrink-0 ml-3 text-sm text-gray-400" >{locale.format("%H:%M")(comments.length===0? parseWithTimeZone(opened_at): parseWithTimeZone(comments[0].created_at) )}</div>
                          </div>
                          <span className="font-medium "  >{code && code.name}</span>
                        </div>
                        </div>
                  </div>
                  <div>
                    {
                      comments.slice(0,1).map(d=><Comment key={d.id}
                                                    author={d.author}
                                                    content={d.content}
                                                    closed_at={closed_at}
                                                    stackedComments={comments.length}
                                                    hideExpand={true}/>)
                    }
                  </div>
                  {comments.length===0 && <Comment  closed_at={closed_at}/>}
               </div> 
            </button>
          </div>
}


export function NoteCommentCard({comments, opened_at, closed_at}){
  return <div className={classnames("group")} >

              <div className="text-sm" >{locale.format("%d %b %H:%M")((comments||[]).length===0? parseWithTimeZone(opened_at): parseWithTimeZone(comments[0].created_at) )}</div>
              <div>
                {
                  (comments||[]).slice(0,1).map(d=><Comment key={d.id}
                                                author={d.author}
                                                content={d.content}
                                                closed_at={closed_at}
                                                stackedComments={(comments||[]).length}
                                                hideExpand={true}/>)
                }
              </div>
              {(comments||[]).length===0 && <Comment  closed_at={closed_at}/>}
            </div>
}







export function NotesList({notes, select}){

  return <Fragment >
            <div className="flex-1 w-full space-y-3 overflow-auto sm:space-y-3 ">
              {notes.map(d=><CompactNoteCell key={`${d.id}`}
                                             select={()=>select(d)}
                                             {...d}/>)}
            </div>

          </Fragment>
}

function TooltipContentCalendar({code, comments, opened_at, closed_at}){
  return <div className={classnames("flex group py-2")}>
             <div className="flex items-center">
               <div className="">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center font-medium"><span className="flex-shrink-0 inline-block w-4 h-4 mr-2 rounded-md" style={{background:code.color}}/>{code.name}</div>
                    <div className="flex-shrink-0 ml-3 text-sm text-gray-500">{locale.format("%d %B %Y à %H:%M")(comments.length===0? parseWithTimeZone(opened_at): parseWithTimeZone(comments[0].created_at) )}</div>
                  </div>
                  {
                    comments.slice(0,1).map(d=><Comment hideExpand={true} key={d.id} author={d.author} closed_at={closed_at} content={d.content}/>)
                  }
                  {comments.length===0 && <Comment closed_at={closed_at}/>}
               </div>
            </div>
          </div>
}

export function ControlledNotesCard({notes, noCalendar, setPage, paging, error}){
  const {filters, dispatchFilters} = useContext(SearchNotesContext);

  const {query, setQuery} = useQuery();
  const [open, setOpen] = useState(query.has("noteId"));

  useEffect(()=>{
    if (!open){setQuery()}
  }, [open])

  const [focusDate, setFocusDate] = useState(filters.date);
  const [date, setDate] = useState(filters.date);
  const windowDates = useMonth(date);

  useEffect(()=>{
    if (!datesAreInInterval(windowDates.fromDate, filters.fromDate, {step: 'day'})){
      if (!noCalendar) dispatchFilters({type: 'dates', value: {...windowDates, date}})
    }
  }, [date, windowDates]);

  const events = useMemo(()=> {
    if (!notes) return;
    return notes.map(d=>{
      const openAt = dateParse(d.opened_at.slice(0,10))
      const latestCommentDate = d.comments.length>0 && parseWithTimeZone(d.comments[0].created_at);
      var date = openAt;
      if (openAt>windowDates.toDate || openAt<windowDates.fromDate && latestCommentDate){
        date = latestCommentDate;
      }
      return {date: date,
                  color: d.code.color,
                  tooltipProps: {color: 'light', onClick: (()=>{setOpen(true); setQuery({noteId: d.id});}) },
                  tooltip: <TooltipContentCalendar {...d}/>}
      })
  }, [notes]);


  if (!notes){
    return    <div className="mt-2 space-y-6 xl:space-y-0 xl:flex">
                  <div className="z-10 flex-1 max-w-sm py-6 pr-6 mx-auto min-w-max 2xl:py-0">
                  <Skeleton.List numElements={1} itemClassName="aspect-square" className="space-y-2 mt-8"/>
              </div>
                <div className="flex-1">
                  <Skeleton.List numElements={3} itemClassName="h-32" className="space-y-2 mt-8"/>
              </div>
              </div>
  }

  return <div className="">
          <FilterBar noteGroup noteCode  />
           <div className="mt-2 space-y-6 xl:space-y-0 xl:flex">
                {!noCalendar &&
                  <div className="z-10 flex-1 max-w-sm py-6 pr-6 mx-auto min-w-max 2xl:py-0">
                    <DoubleMonthView date={date}
                                    onDateChange={(d)=>{setDate(d); setFocusDate(); }}
                                    events={events}
                                    layout="full"
                                    className="w-full"
                                     onClick={d=>{setFocusDate(d); dispatchFilters({type: 'dates', value:{fromDate: timeDay.floor(d), toDate: timeDay.offset(d, 1)}})}}
                                    numMonths={1}/>
                       {focusDate &&
                        <p className="flex items-center justify-between p-2 mt-2 mb-2 space-x-3 bg-gray-100 rounded-lg">
                          {locale.format("%d %B %Y")(focusDate)}
                        <Button color="delete" onClick={()=>{setFocusDate(); setDate(new Date(date))}}><FiX/></Button></p>}
                    {paging&& <Paginate setPage={setPage} {...paging}/>}

                  </div>
                }

                <div className="flex-1">
                  {notes.length===0?
                    <div className="h-full p-3 bg-gray-100 rounded-lg">
                      <Empty/>
                    </div>:
                    <Fragment>
                      <NotesList setPage={setPage} notes={notes} select={(d)=>{setOpen(true); setQuery({noteId: d.id});}}/>
                    {paging&& <Paginate setPage={setPage} {...paging}/>}

                    </Fragment>
                  }
                  {error? <Error.Text className="mt-3" {...error}/>: null}
                </div>
              </div>
              <SlideOver open={open} setOpen={setOpen} size="xl3">
                <NoteExtendedViewQueryBased/>
              </SlideOver>
          </div>
}

export function NotesCardWithContext({student, tutor, team, preset, studySession, pairing, updateContext, noCalendar}){
  const {filters, dispatchFilters} = useContext(SearchNotesContext);
  const validateParams = useCallback(({fromDate, toDate})=>{
    if ((!fromDate || !toDate) && !noCalendar) return false;
    return true;
  }, [])

  useEffect(()=>{
    if (tutor || updateContext){
      dispatchFilters({type: "tutor", value: tutor})
    }
  }, [tutor])

  useEffect(()=>{
    if (student|| updateContext){
      dispatchFilters({type: "student", value: student})
    }
  }, [student])

  useEffect(()=>{
    if (studySession|| updateContext){
      dispatchFilters({type: "studySession", value: studySession})
    }
  }, [studySession])

  useEffect(()=>{
    if (team|| updateContext){
      dispatchFilters({type: "team", value: team})
    }
  }, [team])

  useEffect(()=>{
    if (preset|| updateContext){
      dispatchFilters({type: "preset", value: preset})
    }
  }, [preset])

  useEffect(()=>{
    if (pairing|| updateContext){
      dispatchFilters({type: "pairing", value: pairing})
    }
  }, [pairing])

  const [notes, { error, setPage, paging }] = useSearch(searchNotes, filters, {validateParams, limit:100});

  return <ControlledNotesCard noCalendar={noCalendar} notes={notes} setPage={setPage} paging={paging} error={error}/>
}

export function NotesCard(){
  return <SearchNotesContextProvider>
          <NotesCardWithContext/>
        </SearchNotesContextProvider>
}