import { useState, useCallback, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";


/**
 * Custom hook to handle API calls with various options, including loading and error states.
 *
 * @template T - The expected type of the API response.
 *
 * @param {Function} method - The API method to be executed. It should return a promise.
 * @param {Object} params - The parameters to be passed to the API method.
 * @param {Object} options - Configuration options for the API call.
 * @param {Function} [options.onResult] - Callback function to be invoked with the API response.
 * @param {Function} [options.onCatch] - Callback function to be invoked when an error occurs.
 * @param {boolean} [options.immediate=true] - Whether to automatically execute the API call on mount.
 * @param {Function} [options.validateParams] - Function to validate the parameters before executing the API call.
 * @param {boolean} [options.camelize=false] - If true, transforms response keys to camelCase.
 * @param {Array<string>} [options.casingWhitelist] - List of keys to be excluded from camelizing.
 *
 * @returns {[
 *   T | undefined,
 *   {
 *     loading: boolean,
 *     error: { code: string, detail?: string, payload?: any } | undefined,
 *     execute: (extraParams?: Object) => Promise<void>,
 *     setResult: (result: T) => void,
 *     setError: (error: { code: string, detail?: string, payload?: any }) => void
 *   }
 * ]} - Returns an array where:
 *   - `0` is the API response (or `undefined` if not yet fetched).
 *   - `1` is an object containing:
 *     - `{boolean} loading` - Whether the request is in progress.
 *     - `{Object | undefined} error` - The error object if an error occurs.
 *     - `{Function} execute` - Function to manually trigger the API call.
 *     - `{Function} setResult` - Function to manually update the API response.
 *     - `{Function} setError` - Function to manually update the error state.
 */
export function useAPI(method, params, {onResult, onCatch, immediate=true, validateParams, camelize=false, casingWhitelist}={}){

  const [result, setResult] = useState();
  const [loading, setLoading] = useState(immediate);
  const [error, setError] = useState();
  const activeController = useRef();
  const isMounted = useIsMounted();

  const execute = useCallback((extraParams)=>{
    if (!method) return;
    if (validateParams && !validateParams(params)) return;
    setLoading(true);
    setError();
    const handleSuccess = (res)=>{
      const camelRes = Casing.recursiveCamelize(res, {whitelist: casingWhitelist});
      res = camelize? camelRes: res;
      if (!isMounted.current) return;
      if (onResult){
        onResult(res);
      }
      setResult(res);
      setLoading(false);
      
    }
    const onError = (res)=>{
      if (!isMounted.current) return;
      setLoading(false);
      try {
        res.json()
            .then(({detail, code, payload})=>{setLoading(false); setError({code, detail, payload})})
            .catch(()=>{setLoading(false); setError({code: 'default'}); if (onCatch){onCatch()} });
      }
      catch {
        setLoading(false); 
        if (onCatch) onCatch();
        setError({code: 'default'});
      }
    }

    if (activeController.current) activeController.current.abort();
    const [promise, {controller}] = method(extraParams? ({...extraParams, ...params}): params)
    activeController.current = controller;
    return promise.then(handleSuccess).catch(onError);
  }, [method, onResult, params])

  // Fire when page reload
  useEffect(() => {
    if (immediate) execute();
  }, [execute]);

  return [result, {loading, error, execute, setResult, setError}];
}

