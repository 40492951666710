import React from 'react';

// Components
import { Outlet } from "react-router-dom";
import { SettingsLayout } from 'components/settings/layout';

// Hooks
export function SettingsBase(){
  return <SettingsLayout>
            <Outlet/>
          </SettingsLayout>
}

import {default as schoolSubjects} from 'pages/settings/school_subjects';
import {default as members } from 'pages/settings/members';
import {default as profile } from 'pages/settings/profile';
import {default as presets } from 'pages/settings/presets';
import {default as noteCodes } from 'pages/settings/note_codes';
import {default as playTimeRules } from 'pages/settings/play_time_rules';
import {default as documentGroups } from 'pages/settings/document_groups';
import {default as security } from 'pages/settings/security';
import {default as comments } from 'pages/settings/comment-types';
import {default as mergeTutors} from 'pages/settings/merge-tutors';
import {default as tournamentRules} from 'pages/settings/tournament-rules';
import {default as meetingDates} from 'pages/settings/meeting_dates';
import {default as mailTypes} from 'pages/settings/mail_types';
import { default as aadDates } from 'pages/settings/aad_dates';

export const SettingsPages = {root: SettingsBase,
                            schoolSubjects,
                            members,
                            profile,
                            presets,
                            noteCodes,
                            playTimeRules,
                            mergeTutors,
                            tournamentRules,
                            documentGroups,
                            security,
                            mailTypes,
                            meetingDates,
                            aadDates,
                            comments}
export const nestedSettingsRoutes = [profile, security, members, presets, 
  schoolSubjects, noteCodes, comments, documentGroups, playTimeRules, tournamentRules, mergeTutors, meetingDates, aadDates, mailTypes];