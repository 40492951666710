/* eslint-disable */
import React, { useCallback } from 'react';

// Hooks
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { createNote, searchNoteCode } from 'api/notes';

// Utils
import { recursiveCamelize } from 'utils/casing';

// Components
import { SearchNoteCode } from 'components/tools/search_note_code';
import { SelectDate } from 'components/tools/select_date';
import { SearchPairings } from 'components/tools/search_pairing';
// import { SearchCoach } from 'components/tools/search_coach';
import { SearchStudents } from 'components/tools/student_search';
import { Button } from 'components/core/button';
import { Error, RawTextWarning } from 'components/core/typo'
import { SearchTutors } from 'components/tools/search_tutor';
import { CompactNoteCell } from 'components/notes/list';
import { CheckBox } from 'components/tools/checkbox';
import { Editor } from 'components/core/rich-editor/editor';
import { SearchCommentTypes } from 'components/tools/search-comment-types';
import { CommentTypeBadge } from 'components/tools/search-comment-types';
import { Dropdown } from 'components/core/dropdown';
import { FiBookmark, FiCalendar, FiClock } from 'react-icons/fi';
import { TextInput } from 'components/core/inputs';
import { DisassembledEditor } from 'components/core/rich-editor/disassembled-editor';
import { ActionsPlan } from 'components/actions-plan';
import classNames from 'classnames';
import { RawTextInfo } from 'components/core/typo';
import { useSearch } from 'hooks/useSearch';
import { useInternetConnection } from '@/contexts/internet-connection';
import { useLocalNotes } from '@/contexts/note-storage';
import { CodeBracketSquareIcon } from '@heroicons/react/20/solid';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { useDebounce } from '@/hooks/useDebounce';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { locale } from '@/utils/locale';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { Ban, PencilOff } from 'lucide-react';

const SUGGESTED_CODES = {
    "tutorat": [
        "aide-aux-devoirs-trop-breve-tutorat",
        "rencontre-manquee-sans-avertissement",
        "rencontre-manquee-pour-probleme-technique",
        "rencontre-manquee-pour-une-raison-externe",
        "manque-de-concentration-lors-des-seances",
        "manque-de-materiel-a-son-aide-aux-devoirs",
        "duree-des-seances",
        "retard-a-son-aide-aux-devoirs",
        "camera-fermee",
        "criteres-en-ligne-non-respectes",
        "absence-denregistrement-a-son-aide-aux-devoirs"
    ]
}
function SuggestCodes({ isTutoratStudySession, onSelect }) {
    const validateParams = (params) => {
        return params && params.isTutoratStudySession && params.codes && params.codes.length > 0
    }
    const params = useMemo(() => ({ isTutoratStudySession, codes: SUGGESTED_CODES.tutorat }), [isTutoratStudySession]);
    const [suggestions] = useSearch(searchNoteCode, params, {immediate: true, validateParams});
    if (!suggestions) return null;
    if (!isTutoratStudySession) return null;
    return <div className="inline-block space-x-1 space-y-1 mt-1">
        {suggestions.map(d=><Button size="xs" color="gray" onClick={()=>onSelect(d)} key={d.code}>{d.name}</Button>)}
    </div>
}



export function CreateNote({ 
    defaultStudent, 
    disableStudent,
    defaultComment,
    defaultTutor, 
    defaultPairing, 
    defaultStudySession, 
    defaultCode, 
    disableCode,
    defaultShouldDiscuss,
    defaultLocalNoteId,
    defaultGroup, 
    defaultMinutes,
    defaultOpenedAt,
    defaultCommentTypes,
    defaultClosedAt,
    defaultPeriod, 
    defaultCommentDisplayDate,
    defaultActionsToLink, 
    onResult }) {
    const { t } = useTranslation("common");
    // const { isOnline} = useInternetConnection();
    const {createNote: createLocalBackup, updateNote: updateLocalBackup, deleteNote: deleteLocalBackup} = useLocalNotes();
    const [localNoteId, setLocalNoteId] = useState(defaultLocalNoteId);
    const [code, setCode] = useState(defaultCode || null);
    const [comment, setComment] = useState(defaultComment);
    const debounceComment = useDebounce(comment, 100);
    const [doNotSave, setDoNotSave] = useState(false);
    const [closeAlert, setCloseAlert] = useState(false);
    const [commentTypes, setCommentTypes] = useState(defaultCommentTypes || []);
    const [openedAt, setOpenedAt] = useState(defaultOpenedAt || new Date());
    const [shouldDiscuss, setShouldDiscuss] = useState(defaultShouldDiscuss || false);
    const [commentDisplayDate, setCommentDisplayDate] = useState(defaultCommentDisplayDate || new Date());
    const [closedAt, setClosedAt] = useState(defaultClosedAt || null);
    const [student, setStudent] = useState(defaultStudent || (defaultPairing && defaultPairing.student));
    const [tutor, setTutor] = useState(defaultTutor || (defaultPairing && defaultPairing.tutor));
    const [pairing, setPairing] = useState(defaultPairing);
    const [studySession, setStudySession] = useState(defaultStudySession);
    const [shown, setShown] = useState(false)
    const [minutes, setMinutes] = useState(defaultMinutes);
    const [documentGroup,] = useState(defaultGroup);
    const [period,] = useState(defaultPeriod);
    const [isValid, setIsValid] = useState(false);
    const [view, setView] = useState('note'); // note, actionsPlan
    const [actionsToLink, setActionsToLink] = useState(defaultActionsToLink || []);

    const updateLocalNote = useCallback((note) => {
        if (localNoteId){
            updateLocalBackup(localNoteId, note);
        }
    }, [localNoteId]);

    useEffect(() => {
        if (doNotSave) return;
        if (localNoteId){
            // Should update
            updateLocalNote({
                content: comment,
                code,
                student,
                tutor,
                openedAt,
                closedAt,
                commentTypes,
                minutes,
                shouldDiscuss,
                commentDisplayDate,
                actionsToLink
            });
        }
        else {
            if (!comment && !code && !student && !tutor ){
                // Do not create empty
                return;
            }

            const noteLocalId = createLocalBackup({content: comment, code, student, tutor, openedAt, closedAt, commentTypes, minutes, shouldDiscuss, commentDisplayDate, actionsToLink})
            setLocalNoteId(noteLocalId);
        }
    }, [debounceComment, code, student, tutor, openedAt, closedAt, commentTypes, minutes, shouldDiscuss, commentDisplayDate, actionsToLink]);
    
    const onCreateAction = (action) => {
        setActionsToLink([...actionsToLink, {...action, created:true}]);
    }
    const onDeleteAction = (action) => {
        setActionsToLink(actionsToLink.filter((a) => a.id !== action.id));
    }
    const toggleView = () => setView(view === 'note' ? student ? 'actionsPlan' : 'note' : 'note')
    const handleRemoveCommentType = (commentType) => {
        setCommentTypes(commentTypes.filter((ct) => ct.code !== commentType.code))
    }
    const handleLinkOnCompletion = ({ completed, id }) => {
        setActionsToLink((prev) => {
            if (completed) {
                if (prev.some((a) => a.id === id)) {
                    return prev;
                }
                return [...prev, { id, created: true }];
            } else {
                return prev.filter((a) => a.id !== id || a.created);
            }
        });
    };

    const params = useMemo(() => ({
        studySession: studySession,
        student: (student && student.ni) || (pairing && pairing.student && pairing.student.ni),
        tutor: (tutor && tutor.ni) || (pairing && pairing.tutor && pairing.tutor.ni),
        code: code && code.code,
        comment,
        commentCodes: commentTypes.map((ct) => ct.code),
        pairing: pairing && pairing.id,
        shouldDiscuss,
        commentDisplayDate,
        minutes: minutes || 0,
        // coach: coach&&coach.ni,
        openedAt,
        closedAt,
        documentGroup,
        period,
        commentsToLink: actionsToLink
    }),
        [student, tutor, code, studySession, openedAt, comment, minutes, commentDisplayDate, commentTypes, closedAt, pairing, shouldDiscuss, period, documentGroup]);
    const handleResult = (note) => {
        deleteLocalBackup(localNoteId);
        setLocalNoteId(null);
        onResult?.(recursiveCamelize(note))
    }
    const [note, { loading, error, execute }] = useAPI(createNote, params, { immediate: false, onResult: handleResult })
    useEffect(() => {
        // waiting for component to be mounted (to avoid RichEditor render error)
        setShown(true)
    }, []);
    const searchStudentExtraParams = useMemo(() => ({ leftService: false, 
                                                 }), []);
    if (note) {
        return <div className="justify-center px-3 py-6">
            <p className="mb-6 text-2xl text-center">{t("note-has-been-created")}</p>
            <div className="p-3 bg-gray-100 rounded-xl">
                <CompactNoteCell {...note} select={null} />
            </div>
        </div>
    }
    return <>
        <div className="relative">
            {localNoteId && !closeAlert?
                <Alert className='flex items-center justify-between mb-3'>
                    <div className='max-w-[900px]'>
                    <AlertTitle>{t("alert-local-note-saved.title")}</AlertTitle>
                    <AlertDescription>
                    {t("alert-local-note-saved.description")}
                    </AlertDescription>
                    </div>
                    <div className='flex gap-2'>
                    <Button color="outline" size="sm" onClick={()=>{setCloseAlert(true); setDoNotSave(false);}}>{t("alert-local-note-saved.understand")}</Button>
                    <Button color="blackNoSize" size="sm" onClick={()=>{setCloseAlert(true); setDoNotSave(true); deleteLocalBackup(localNoteId)}}>{t("alert-local-note-saved.delete")}</Button>
                    </div>
                </Alert>:null
            }
            {doNotSave && <p className='text-red-600'>{t("note-not-saved")}</p>}
            {!doNotSave && closeAlert && <p className='text-cyan-600'>{t("note-saved-live")}</p>}
            <div className='pr-12 mb-4 mt-0 max-w-[800px]'>
                <SearchNoteCode disabled={disableCode} 
                placeholder={t("select-note-type")} 
                showGroupFilter 
                size="xl" 
                value={code} 
                setValue={setCode} 
                targetWidth={"full"} 
                color={code? "transparent" :"orangeOutline"} />
                 {!code && <SuggestCodes isTutoratStudySession={studySession && studySession.code && studySession.code.code === 'tutorat'} onSelect={setCode} />}
            </div>
                <div className="lg:grid lg:grid-cols-3 lg:gap-4">
                    <div className='lg:col-span-2'>
                        

                        <Tabs defaultValue="editor">
                            <TabsList>
                                <TabsTrigger value="editor">{t("note-editor")}</TabsTrigger>
                                <TabsTrigger disabled={!student} value="actions">
                                {t("actions-plan")}
                                </TabsTrigger>
                                {!student && <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger>{<PencilOff className='h-4 w-4 text-muted-foreground/50'/>}</TooltipTrigger>
                                        <TooltipContent>
                                        {t("select-student-to-create-actions")}
                                        </TooltipContent>
                                    </Tooltip>
                                    </TooltipProvider>}
                            </TabsList>
                            <TabsContent value="actions">
                                <div className={classNames('mt-3 flex flex-col gap-2')}>
                                    <RawTextInfo title={t("actions-created-will-be-linked")}/>
                                    <ActionsPlan student={student} hideStats onCreateAction={onCreateAction} onDeleteAction={onDeleteAction} onToggleCompleted={handleLinkOnCompletion} bypassCompletionComment />
                                    <div className='flex justify-end'>
                                        <Button color={"default"} disabled={!student} size="md" className="w-fit" onClick={toggleView}>
                                            {t("return-to-note-editor")}
                                        </Button>
                                    </div>
                                </div>
                                </TabsContent>
                            <TabsContent value="editor">
                            <div className='flex items-start '>
                            {/* Toolbar */}
                            <div className='flex items-center mt-0.5 mr-1 gap-2 flex-wrap'>

                                <Dropdown onlyIcon={<FiBookmark />}
                                    labelWithIcon={commentTypes? `${commentTypes.length} tags`: t("comment-type")}
                                    tooltip={t("comment-type")}
                                    itemClassName="w-[300px]"
                                    orientation="left"
                                    preventCloseOnClick
                                    color={commentTypes && commentTypes.length > 0 ? 'activeColor' : 'default'}
                                >
                                    
                                    <div className='flex flex-wrap mb-1'>
                                        {
                                            commentTypes &&
                                            commentTypes.map((commentType, index) => (
                                                <CommentTypeBadge key={'selectCmtTypes' + index} className='mt-1 mr-1' removable={true} onRemove={() => handleRemoveCommentType(commentType)} text={commentType.name} color={commentType.color} />
                                            ))
                                        }
                                    </div>
                                    <div className='py-1'>
                                        <SearchCommentTypes 
                                        value={commentTypes} 
                                        setValue={setCommentTypes} size="smWider" vOrientation="top" />
                                    </div>

                                </Dropdown>

                                <Dropdown onlyIcon={<FiClock />} labelWithIcon={
                                    minutes? `${minutes} ${t("minutes")}`:t("comment-minutes.label")
                                }
                                    color={minutes ? 'activeColor' : 'default'}
                                    tooltip={t("comment-minutes.label")} itemClassName="w-[300px]" orientation="left">
                                    {setMinutes && <div className="pt-1"><TextInput value={minutes != null ? minutes : ""}
                                        size="sm" type="number" color={"gray"} min="0" step="1" xsDescription={t("comment-minutes.label")}
                                        onChange={e => setMinutes(parseInt(e))}
                                        placeholder={t("comment-minutes.placeholder")} /></div>}
                                </Dropdown>

                                <Dropdown onlyIcon={<FiCalendar />}
                                    labelWithIcon={commentDisplayDate? locale.format("%d %B")(commentDisplayDate) :t("date-of-comment")}
                                    color={commentDisplayDate ? 'activeColor' : 'default'}
                                    tooltip={t("date-of-comment")} 
                                    itemClassName="w-[300px]" 
                                    orientation="left">
                                    {setCommentDisplayDate && <div className="pt-1"><SelectDate xsDescription={t(`date-of-comment`)} value={commentDisplayDate} setValue={setCommentDisplayDate} orientation="right" vOrientation="down" canRemoveSelection={false} /></div>}
                                </Dropdown>
                              
                            </div>
                        </div>


                        <label htmlFor="description" className="sr-only">
                            Description
                        </label>

                                <div >
                                    { actionsToLink?.length > 0 && <RawTextInfo className="mt-2" title={t(actionsToLink?.length === 1 ? "count-actions-will-be-linked-singular":"count-actions-will-be-linked-plural", {count:actionsToLink?.length})}/>}
                                    {
                                        // Wait for component to be mounted to avoid RichEditor render error
                                        shown && <div className=''>
                                            {
                                                code?.comments_config ?
                                                    <DisassembledEditor config={code?.comments_config} defaultValue={comment} setText={setComment} setIsValid={setIsValid} />
                                                    :
                                                    <div className='mt-2'>
                                                        <Editor editorStyles="text-black h-80"
                                                            toolbarStyles='mt-0'
                                                            name="description"
                                                            id="description"
                                                            placeholder={t("note-comment-placeholder")}
                                                            text={comment}
                                                            setText={setComment}
                                                        />
                                                    </div>
                                            }

                                        </div>
                                    }
                                </div>
                                </TabsContent>
                            </Tabs>

                            
                            

                    </div>
                    <div className='pt-3 mt-3 border-t rounded-md rounded-t-none lg:col-span-1 lg:bg-white lg:pt-0 lg:border-l lg:pl-3 lg:rounded-none lg:mt-0 lg:border-t-0'>
                        <div className="grid w-full grid-cols-1 gap-3 !px-0 py-2  sm:p-3 rounded-xl ">
                            <div className="flex items-center justify-between ">
                                <div className="flex flex-col w-full gap-2">
                                    <SelectDate value={openedAt} setValue={setOpenedAt} label={t("opened-at")} orientation="right" />
                                    <SelectDate value={closedAt} setValue={setClosedAt} label={t("ended-at")} orientation="right" />
                                </div>
                            </div>
                           
                            <div className='pt-6 grid w-full grid-cols-1 gap-1 !px-0 py-2  sm:p-3 rounded-xl '>
                                <label className='text-gray-500 text-sm'>{t("attached-to")}</label>
                                {
                                    studySession &&
                                    <div className="bg-gray-200  px-3 py-0.5 font-medium border-2 border-black  rounded-md flex justify-between gap-3 items-center">
                                        <p>{`${studySession.code && studySession.code.name} du ${studySession.period} avec ${studySession.student && studySession.student.name} (id: ${studySession && studySession.id})`}</p>
                                        <Button color="delete" size="sm" onClick={() => setStudySession()}>{t("remove")}</Button>
                                    </div>
                                }
                                <SearchStudents size="lg" value={student || (pairing && pairing.student)} setValue={setStudent} disabled={studySession || pairing || disableStudent} extraParams={searchStudentExtraParams} targetWidth={"full"} color="default" />
                                <SearchTutors size="lg" targetWidth="full" value={tutor || (pairing && pairing.tutor)} setValue={setTutor} disabled={studySession || pairing} color="default" />
                                <SearchPairings value={pairing}
                                    setValue={setPairing}
                                    size="lg"
                                    student={student && student.ni}
                                    tutor={tutor && tutor.ni}
                                    active={true}
                                    disabled={(!student && !tutor) || studySession}
                                    color="default"
                                    targetWidth={"full"} />
                             
                                {/* <SearchCoach value={coach}
                    setValue={setCoach}
                    size="lg"
                    active={true}
                    label={t("attach-to-a-coach")}
                    color="white"
                    targetWidth={"full"}/>*/}
                               
                            </div>
                            <div className='pt-6'>
                                <CheckBox marker="select" value={shouldDiscuss} setValue={setShouldDiscuss} name={t("should-discuss-case")} orientation="right" />
                            </div>

                        </div>
                        {
                            actionsToLink?.length > 0 && <RawTextWarning>
                                {t(actionsToLink?.length === 1 ? "create-note-to-link-singular":"create-note-to-link-plural", {count: actionsToLink?.length})}
                            </RawTextWarning>
                        }
                        <div className="flex items-center justify-between py-2 my-2 ">

                            <Button onClick={execute}
                                loading={loading}
                                disabled={(!student && !tutor) || !code || (code && code.comments_config && !isValid) || (!minutes && minutes !== 0)}
                                color="black"
                                size="lg"
                                block
                            >{t('create-note')}</Button>
                            {error ? <Error.Text {...error} /> : null}
                        </div>
                    </div>
                </div>


        </div>
    </>
}

