// Hooks
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useMemo, useCallback } from 'react';
import { useAPI } from 'hooks/useAPI'

// API
import { retrieveUser } from 'api/auth';

export function useUser(fetchDetails=false){
  const [user, setUser] = useLocalStorage("user", {});

  const params = useMemo(()=>{}, []);
  const onResult = useCallback(setUser, []);
  const [, {loading, execute}] = useAPI(retrieveUser, params, {immediate: fetchDetails, onResult})

  return [user, setUser, {loading, execute}];
}

export function useUserV2(fetchDetails=false){
  const [user, setUser] = useLocalStorage("user", {});

  const params = useMemo(()=>{}, []);
  const onResult = useCallback(setUser, []);
  const [, {loading, execute}] = useAPI(retrieveUser, params, {immediate: fetchDetails, onResult})

  return {user, setUser, loading, execute};
}