import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from "sonner"

interface InternetConnectionProviderProps {
  children: React.ReactNode;
}

export const InternetConnectionContext = createContext(false);

export const InternetConnectionProvider = ({ children }: InternetConnectionProviderProps) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const {t} = useTranslation();

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine) {
        toast.error(t("internet-connection-offline-error"))
      }
      else {
        toast.success(t("internet-connection-is-back"))
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <InternetConnectionContext.Provider value={isOnline}>
      {children}
    </InternetConnectionContext.Provider>
  );
};

interface InternetConnectionContextProps {
  isOnline: boolean;
}
export function useInternetConnection(): InternetConnectionContextProps {
  const isOnline = React.useContext(InternetConnectionContext);
  return {isOnline};
}