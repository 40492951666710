import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';
import { Casing } from "utils/casing";


/**
 * Custom hook to perform a search operation with pagination and error handling.
 *
 * @template T - The type of the search result items.
 * @param {Function} method - The search method to be executed. It should return a promise resolving to search results.
 * @param {Object} params - The parameters to be passed to the search method.
 * @param {Object} options - Additional options for the search operation.
 * @param {Function} options.onResult - Callback function to be called with the search results.
 * @param {boolean} [options.camelize=false] - Whether to camelize the response keys.
 * @param {Array<string>} [options.casingWhitelist] - List of keys to be excluded from camelizing.
 * @param {number} [options.limit=50] - The number of results per page.
 * @param {boolean} [options.immediate=true] - Whether to execute the search immediately on mount.
 * @param {Array<string>} [options.fields] - Static fields to be included in the search.
 * @param {Array<string>} [options.dynamicFields] - Dynamic fields to be included in the search.
 * @param {Function} [options.validateParams] - Function to validate the search parameters.
 *
 * @returns {[T[], {
 *   loading: boolean,
 *   error: Object | null,
 *   setResult: (result: T[]) => void,
 *   execute: () => Promise<void>,
 *   page: number,
 *   paging: { page: number, limit: number, totalPages: number, numPageResults: number, numResults: number } | null,
 *   setError: (error: Object) => void,
 *   setLoading: (loading: boolean) => void,
 *   setPage: (page: number) => void,
 *   hasMore: boolean
 * }]} - Returns an array with search results and an object containing search state and actions.
 */
export function useSearch(method, params, { onResult,
    camelize = false,
    casingWhitelist,
    limit = 50,
    immediate = true,
    fields,
    dynamicFields,
    validateParams } = {}) {
    const [page, setPage] = useState(1);
    const [paging, setPaging] = useState();
    const [result, setResult] = useState();
    const [loading, setLoading] = useState(immediate);
    const [error, setError] = useState();
    const activeController = useRef();
    const isMounted = useIsMounted();

    const execute = useCallback(() => {
        if (!method) return;
        if (validateParams && !validateParams(params)) return;
        setLoading(true);
        setError();
        const handleSuccess = (res) => {
            const defaultWhiteListed = ["page", "limit", "total_pages", "num__page_results", "num_results"]
            if (camelize) casingWhitelist = casingWhitelist ? [...casingWhitelist, ...defaultWhiteListed] : defaultWhiteListed;
            const camelRes = Casing.recursiveCamelize(res, { whitelist: casingWhitelist });
            res = camelize ? camelRes : res;
            if (!isMounted.current) return;
            setResult(res.results);
            setPaging({ page: res.page, limit: res.limit, totalPages: res.total_pages, numPageResults: res.num_results, numResults: res.total_results })
            setLoading(false);
            if (onResult) {
                return onResult(res);
            }
        }
        const onError = (res) => {
            if (!isMounted.current) return;
            setLoading(false);
            try {
                res.json()
                    .then(({ detail, code, payload }) => { setLoading(false); setError({ code, detail, payload }) })
                    .catch(() => { setLoading(false); setError({ code: 'default' }) });
            }
            catch {
                setLoading(false);
                setError({ code: 'default' });
            }
        }
        if (activeController.current) activeController.current.abort();
        const [promise, { controller }] = method({ ...params, fields: fields || dynamicFields, page, limit })
        activeController.current = controller;
        return promise.then(handleSuccess).catch(onError);
    }, [method, onResult, params, dynamicFields, page])

    const hasMore = useMemo(() => {
        if (!paging) return;
        return (paging.page < paging.totalPages) || paging.page > 1;
    }, [paging]);

    // Fire when page reload
    useEffect(() => {
        if (immediate) execute();
    }, [execute]);

    return [result, { loading, error, setResult, execute, page, paging, setError, setLoading, setPage, hasMore }];
}

