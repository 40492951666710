import React from "react";
import { useLocalNotes } from "@/contexts/note-storage";
import { useTranslation } from "react-i18next";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import classNames from "classnames";
import { locale } from "@/utils/locale";


function getRawContent(content?: string){
    /* The content is XML encoded. This function will return the raw content */
    const parser = new DOMParser();
    // Check if the content is XML encoded
    if (!content) {return "";}
    const is_xml = content.startsWith("<") && content.endsWith(">");
    if (!is_xml){
      return content;
    }
    try {
      // If the content is not wrapped into a single tag, we wrap it into a div
      const wrapped = content.startsWith("<div>")? content: `<div>${content}</div>`;
      // Replace all <br> by <br/> to avoid parsing errors
      const br = new RegExp("<br>", "g");
      const wrapped_br = wrapped.replace(br, "<br/>");
      // Parse the content
      console.log(wrapped_br)
      const doc = parser.parseFromString(wrapped, "application/xml");
      return doc.documentElement.textContent;
    }
    catch {
      return content;
    }
  }

export function NoteHistoryCells(){
    const {notes, selectedNote, setSelectedNote} = useLocalNotes();
    const {t} = useTranslation();
    if (notes.length === 0) {
        return <div className="p-2"><Alert className="">
        <AlertTitle>{t('local-notes-empty-state.title')}</AlertTitle>
        <AlertDescription>
            {t('local-notes-empty-state.description')}
        </AlertDescription>
      </Alert></div>
           
    }
    return <div className="divide-y">
        {notes.map(note => (
            <div key={note.id} 
            className={classNames("py-3 px-2 cursor-pointer ", 
              selectedNote?.id === note.id ? "bg-orange-500 text-white" : "hover:bg-gray-100")}
            onClick={() => setSelectedNote(note.id)}>
              <p className="text-sm">{locale.format("%d %b à %H:%M")(new Date(note.lastUpdated))}</p>
                <p className="font-bold">{note.student? note.student.name: note.tutor? note.tutor.name: t("unassigned")}</p>
                <h3 className="font-medium line-clamp-1 text-sm">{note.code? note.code.name : t("no-note-code")}</h3>
                <p className="line-clamp-3  text-sm">{note.content?getRawContent(note.content): t("no-comment")}</p>
            </div>
        ))}
    </div>;
 }
