import React from 'react';

// Components
import { DashboardLayout } from 'components/core/layouts';
import { Container } from 'components/core/container';
import Markdown from 'markdown-to-jsx';
import { options } from 'mdx';

import { content as contentStaging} from 'mdx/staging.changelog';
import { content as contentProd} from 'mdx/prod.changelog';


export function ChangeLogPage(){
  return <DashboardLayout>
          <Container className="w-full h-full max-w-4xl p-6 mx-auto">
            <Markdown options={options}>{process.env.REACT_APP_APIDOMAIN == "https://api.app.diplomeavantlamedaille.org" ?  contentProd: contentStaging}</Markdown>
          </Container>
         </DashboardLayout>
}

const route =  { path: "/changelog",
                 requireAuth: true,
                 Element: ChangeLogPage };
export default route;
