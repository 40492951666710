import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Editor } from 'components/core/rich-editor/editor';
import { SubLabel, LargeLabel } from 'components/tools/modifiable_fields';

/**
 * Assembles an array of objects containing text into a single HTML string.
 *
 * @param {Array} disassembledText - An array of objects, each containing a `label` and `text` property.
 * @returns {string} - A single HTML string with each text wrapped in a paragraph and label in a strong tag.
 */
export function assembleText(disassembledText) {
    return (
        disassembledText
            ?.filter(v => v && typeof v?.text === 'string' && v?.text?.trim() !== '' && v?.text?.replace(/<[^>]*>?/gm, '') !== '')
            .map(({ label, text }) => {
                const safeLabel = label || "";
                const safeText = text || "";
                const labelId = safeLabel.replace(/\s/g, '-').toLowerCase();
                // Remove accents
                const safeLabelId = labelId.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                const labelHtml = `<p><u id="${safeLabelId}"><strong>${safeLabel}</strong></u></p>`;
                return `${labelHtml}<div id="text-${safeLabelId}">${safeText}</div>`;
            })
            .join('<p><br/></p>')
    )
}

function getLabelContent(value, label){
    if (!value) return null;
                
    const parser = new DOMParser();
    const is_xml = value.startsWith("<") && value.endsWith(">");
    if (!is_xml) return null;
    try {
        const wrapped = value.startsWith("<div>")? value: `<div>${value}</div>`;
        const doc = parser.parseFromString(wrapped, "application/xml");
        const labelId = label.replace(/\s/g, '-').toLowerCase();
        const safeLabelId = labelId.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        const labelElement = doc.querySelector(`#text-${safeLabelId}`);
        return labelElement?.innerHTML || null;
    } catch  {
        return null;
    }
}


export function DisassembledEditor({ config, defaultValue, setText, setIsValid }) {
    const {comments: configComments} = config || {comments: []};
    const [comments, setComments] = useState(
        configComments?.map(item => {
            return {
                label: item?.label,
                text: getLabelContent(defaultValue, item?.label) || item?.text || '',
                required: item?.required || false
            }})
    );

    useEffect(() => {
        const assembled = assembleText(comments || []);
        setText?.(assembled);
    }, [comments]);

    const handleTextChange = (index, newText) => {
        setComments(prevComments => {
            const updatedComments = [...prevComments];
            if (index < 0 || index >= updatedComments.length) return updatedComments;
            updatedComments[index].text = newText;
            return updatedComments;
        });
    };
    
    useEffect(() => {
        const v = comments?.every(({ text, required }) => !required || (text && text?.replace(/<[^>]*>?/gm, '').trim() !== ''));
        setIsValid?.(v);
    }, [comments]);
    
    return (
        <div className='relative pt-12 mt-2 p-0.5 pb-2 w-full'>
            <div className='flex flex-col gap-2 w-full'>
                {configComments?.map(({ label, placeholder, sublabel, description, required }, index) => (
                    <div key={index} className="group">
                        {label && <div className='ml-0.5 mt-4 sm:mt-0'><LargeLabel>{label}{required && <span className='text-red-500 ml-0.5'>*</span>}</LargeLabel></div>}
                        <p className='whitespace-pre-wrap text-gray-500 ml-0.5 text-sm mb-0.5'>{description}</p>
                        <Editor
                            editorStyles="text-black"
                            minHeight={"100px"}
                            toolbarStyles={classNames(
                                'absolute top-0 left-0 w-[calc(100%-8px)] py-2',
                                "group-focus-within:visible",
                                index === 0 ? 'visible' : 'invisible'
                            )}
                            name={label}
                            id={index}
                            text={comments[index]?.text}
                            setText={(v) => handleTextChange(index, v)}
                            placeholder={placeholder}
                            hideToolbarToggle
                        />
                        {sublabel && <div className='mt-0.5 ml-0.5'><SubLabel>{sublabel}</SubLabel></div>}
                    </div>
                ))}
            </div>
        </div>
    );
}