import { fetchCall } from 'api';

/*
  Log in
*/  
export const login = ({username, password}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/login",
    form: {username, password}
  }
  return fetchCall(options)
};

/*
  Log in
*/  
export const answerSMSChallenge = ({token, answer}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/answer-challenge",
    body: {token, answer}
  }
  return fetchCall(options)
};

export const resendChallenge = ({token}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/resend-challenge-sms",
    query: {token}
  }
  return fetchCall(options)
};

/*
  Refresh the access token
*/  
export function refreshAccessToken(){
  const options = {
    method: "POST", 
    endpoint: "/app/auth/update-token"
  }
  return fetchCall(options)
}


/*
  Check if user is logged in
*/
export const isLoggedIn =() => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/is-logged-in",
  }
  return fetchCall(options)
};

/*
  Fetch user
*/
export const retrieveUser =() => {
  const options = {
    method: "GET", 
    endpoint: "/app/auth/user",
  }
  return fetchCall(options)
};

/*
  Logout (delete httponly cookies)
*/
export const logout = () => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/logout",
  }
  return fetchCall(options)
};

/*
  Sign up. 
*/
export const signup = ({token, password, email, firstname, lastname}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/signup",
    body: {token, email, password, firstname, lastname}
  }
  return fetchCall(options)
};


/*
  Retrieve MFA status
*/
export const retrieveMFAStatus = () => {
  const options = {
    method: "GET", 
    endpoint: "/app/auth/mfa-status"
  }
  return fetchCall(options)
};

/*
  Retrieve recent connections
*/
export const listRecentConnections = ({page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/app/auth/connections",
    query: {
      page, 
      limit, 
      orderBy, 
      orderDirection}
  }
  return fetchCall(options)
};

/*
  Setup MFA
*/
export const setupMFAPhone = ({phone}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/mfa-phone",
    body: {phone}
  }
  return fetchCall(options)
};


/*
  Change email address for a certain account
*/
export const changeEmail = ({email}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/change-email-address",
    body: {email}
  }
  return fetchCall(options)
};


/*
  Change password
*/
export const changePassword = ({password, currentPassword}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/change-password",
    body: {password, currentPassword}
  }
  return fetchCall(options)
};


/*
  Send an email to reset the password
*/
export const sendResetPasswordEmail = ({email}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/send-reset-password-email",
    body: {email}
  }
  return fetchCall(options)
};

/*
Reset the password with a signed token.
*/
export const resetPassword = ({password, token}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/reset-password",
    body: {password, token}
  }
  return fetchCall(options)
};


/*
Validate the current token
*/
export const validateResetPasswordToken = ({ token}) => {
  const options = {
    method: "GET", 
    endpoint: "/app/auth/validate-reset-password",
    query: {token}
  }
  return fetchCall(options)
};


/*
  Invite user
*/
export const inviteUser = ({email, name, role, message}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/send-invite-signup",
    body: {email, name, message, role: role&& role.name}
  }
  return fetchCall(options)
};

/*
Generate OTP
*/
export const generateOTP = ({password}) => {
  const options = {
    method: "POST", 
    endpoint: "/app/auth/generate-otp",
    body: {password}
  }
  return fetchCall(options)
}



/*
Generate URI for setup
*/
export const generateTOTPUri = () => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/generate-totp-uri"
  }
  return fetchCall(options)
};

/*
Validate totp 
 */
export const validateTOTP = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/validate-totp",
    body: {token}
  }
  return fetchCall(options)
}

// Deactive totp
export const deactivateTOTP = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/deactivate-totp",
    body: {token}
  }
  return fetchCall(options)
}

/*
Validate totp 
 */
export const ansertTOTPChallenge = ({token}) => {
  const options = {
    method: "POST",
    endpoint: "/app/auth/answer-totp-challenge",
    body: {token}
  }
  return fetchCall(options)
}